import React, { useEffect } from "react";
import SSRProvider from 'react-bootstrap/SSRProvider';
import { navigate } from 'gatsby';
import { Script } from "gatsby"
import { connect, useSelector } from "react-redux";
import { actionTypes } from '../store/InvestorQualification/type';
import { moduleTypes } from '../store/type';
import Dialog from "../components/Dialog";
import Loader from "../components/Loader";
import EntityContactInfo from "../components/InvestorQualification/EntityContactInfo";
import EntityInfo from "../components/InvestorQualification/EntityInfo";
import EntityStructure from "../components/InvestorQualification/EntityStructure";
import EntityVerification from "../components/InvestorQualification/EntityVerification";
import IndividualContactInfo from "../components/InvestorQualification/IndividualContactInfo";
import IndividualVerification from "../components/InvestorQualification/IndividualVerification";
import InvestorType from "../components/InvestorQualification/InvestorType";
import QualificationItemOne from "../components/InvestorQualification/QualificationItem/QualificationItemOne";
import QualificationItemTwo from "../components/InvestorQualification/QualificationItem/QualificationItemTwo";
import RIAContactInfo from "../components/InvestorQualification/RIAContactInfo";
import RIAInfo from "../components/InvestorQualification/RIAInfo";
import RIAVerification from "../components/InvestorQualification/RIAVerification";
import TrustContactInfo from "../components/InvestorQualification/TrustContactInfo";
import TrustInfo from "../components/InvestorQualification/TrustInfo";
import TrustVerification from "../components/InvestorQualification/TrustVerification";
import IndividualType from "../components/InvestorQualification/IndividualType";
import "../styles/pages/investor-qualification.scss";
import IndividualJointContactInfo from "../components/InvestorQualification/IndividualJointContactInfo";
import IndividualQualificationItemOne from "../components/InvestorQualification/QualificationItem/IndividualQualificationItemOne";
import IndividualQualificationItemTwo from "../components/InvestorQualification/QualificationItem/IndividualQualificationItemTwo";
import BusinessQualificationItemOne from "../components/InvestorQualification/QualificationItem/BusinessQualificationItemOne";
import BusinessQualificationItemTwo from "../components/InvestorQualification/QualificationItem/BusinessQualificationItemTwo";
import TrustQualificationItemOne from "../components/InvestorQualification/QualificationItem/TrustQualificationItemOne";
import TrustQualificationItemTwo from "../components/InvestorQualification/QualificationItem/TrustQualificationItemTwo";
import BusinessQuestion from "../components/InvestorQualification/BusinessQuestion"
import IndividualQuestion from "../components/InvestorQualification/IndividualQuestion";
import NewIssuesCertification from "../components/InvestorQualification/NewIssuesCertification";
import SpinningProhibitionCertification from "../components/InvestorQualification/SpinningProhibitionCertification";

const InvestorQualification = ({ activeStep, location, updateActiveStep, loading }) => {

  const params = new URLSearchParams(location.search);
  const step = params.get("step");

  const newAccount = useSelector(
    (state) => state?.accounts?.newAccount
  );

  console.log(activeStep);

  useEffect(() => {
    if (step === 'add-client') { //after RIA account verified and click "add client" in Hero, show invester type qualification
      updateActiveStep('investor-type', false);
      navigate('/investor-qualification?step=investor-type');
    }else if ((activeStep === "individual-verified" ||
      activeStep === "trust-verified" ||
      activeStep === "entity-verified" ||
      activeStep === "ria-verified") && newAccount?._id && step !== 'investor-type'
    ) {
      navigate(`/accounts/?verified=${newAccount._id}`)
    }
  }, [activeStep, newAccount, step])
  if (loading) return <Loader />
  return (
    <SSRProvider>
      <Script id="ze-snippet" src="https://static.zdassets.com/ekr/snippet.js?key=e5d637d3-794f-4bf2-a312-c029f226b993" />
      <div className="investor-qualification">
        {(() => {
          switch (activeStep) {
            case "qualification-item-one":
              return (
                <QualificationItemOne
                  handleOptionClick={(e, qual) => updateActiveStep(e, qual)}
                />
              );
            case "qualification-item-two":
              return (
                <QualificationItemTwo
                  handleOptionClick={(e, qual) => updateActiveStep(e, qual)}
                />
              );
            case "qualification-individual-one":
              return (
                <IndividualQualificationItemOne
                  handleOptionClick={(e, qual) => updateActiveStep(e, qual)}
                />
              );
            case "qualification-individual-two":
              return (
                <IndividualQualificationItemTwo
                  handleOptionClick={(e, qual) => updateActiveStep(e, qual)}
                />
              );
            case "qualification-business-one":
              return (
                <BusinessQualificationItemOne
                  handleOptionClick={(e, qual) => updateActiveStep(e, qual)}
                />
              );
            case "qualification-business-two":
              return (
                <BusinessQualificationItemTwo
                  handleOptionClick={(e, qual) => updateActiveStep(e, qual)}
                />
              );
            case "qualification-trust-one":
              return (
                <TrustQualificationItemOne
                  handleOptionClick={(e, qual) => updateActiveStep(e, qual)}
                />
              );
            case "qualification-trust-two":
              return (
                <TrustQualificationItemTwo
                  handleOptionClick={(e, qual) => updateActiveStep(e, qual)}
                />
              );
            case "individual-question":
              return (
                <IndividualQuestion
                  handleOptionClick={(e, qual) => updateActiveStep(e, qual)}
                />
              );
            case "business-question":
              return (
                <BusinessQuestion
                  handleOptionClick={(e, qual) => updateActiveStep(e, qual)}
                />
              );
            case "new-issues-certification":
              return (
                <NewIssuesCertification
                  handleOptionClick={(e, qual) => updateActiveStep(e, qual)}
                />
              );
            case "spinning-prohibition-certification":
              return (
                <SpinningProhibitionCertification
                  handleOptionClick={(e, qual) => updateActiveStep(e, qual)}
                />
              );
            case "investor-type":
              return (
                <InvestorType
                  handleOptionClick={(e) => updateActiveStep(e)}
                />
              );
            case "individual-type":
              return (
                <IndividualType
                  handleOptionClick={(e) => updateActiveStep(e)}
                />
              )
            case "investor-type-individual":
              return (
                <IndividualContactInfo
                  handleOptionClick={(e) => updateActiveStep(e)}
                />
              );
            case "investor-type-joint":
              return (
                <IndividualJointContactInfo
                  handleOptionClick={(e) => updateActiveStep(e)}
                />
              );
            case "investor-type-sd-ira":
              return (
                <IndividualContactInfo
                  handleOptionClick={(e) => updateActiveStep(e)}
                />
              );
            case "individual-verification":
              return (
                <IndividualVerification
                  handleOptionClick={(e) => updateActiveStep(e)}
                />
              );
            case "investor-type-entity":
              return (
                <EntityStructure
                  handleOptionClick={(e) => updateActiveStep(e)}
                />
              );
            case "entity-structure-trust":
              return (
                <TrustInfo
                  handleOptionClick={(e) => updateActiveStep(e)}
                />
              );
            case "investor-type-trust":
              return (
                <TrustContactInfo
                  handleOptionClick={(e) => updateActiveStep(e)}
                />
              );
            case "trust-verification":
              return (
                <TrustVerification
                  handleOptionClick={(e) => updateActiveStep(e)}
                />
              );
            case "entity-structure-s-corp":
              return (
                <EntityInfo
                  handleOptionClick={(e) => updateActiveStep(e)}
                />
              );
            case "investor-type-business":
              return (
                <EntityContactInfo
                  handleOptionClick={(e) => updateActiveStep(e)}
                />
              );
            case "entity-verification":
              return (
                <EntityVerification
                  handleOptionClick={(e) => updateActiveStep(e)}
                />
              );
            // case "investor-type-ria":
            //   return (
            //     <RIAInfo
            //       handleOptionClick={(e) => updateActiveStep(e)}
            //     />
            //   );
            case "investor-type-ria":
              return (
                <RIAContactInfo
                  handleOptionClick={(e) => updateActiveStep(e)}
                />
              );
            case "ria-verification":
              return (
                <RIAVerification
                  handleOptionClick={(e) => updateActiveStep(e)}
                />
              );
            default:
              <></>;
          }
        })()}
      </div>
      <Dialog />
    </SSRProvider>
  );
};

const mapStateToProps = (state) => {
  return {
    activeStep: state[moduleTypes.INVESTOR_QUALIFICATION].activeStep,
    loading: state[moduleTypes.APP].loading
  }
}
function mapDispatchToProps(dispatch) {
  return {
    updateActiveStep: (value, qual = undefined) => {
      dispatch({ type: actionTypes.UPDATE_ACTIVE_STEP, value, qual })
    },
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(InvestorQualification);
