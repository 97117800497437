import React, { useState, useEffect, useLayoutEffect } from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import { RichText } from "prismic-reactjs";
import clsx from "clsx";
import { Link } from "gatsby";
import { client as prismicClient } from "../../../libs/prismic";
import { isMobile } from "../../../utils";
import Loader from "../../Loader";
import { actionTypes } from '../../../store/InvestorQualification/type';
import { actionTypes as dialogActionTypes } from "../../../store/Dialog/type";
import RIAContactInfoContent from "../RIAContactInfoContent";


const RIAContactInfo = ({ handleOptionClick }) => {
  const dispatch = useDispatch();
  const [seeMore, setSeeMore] = useState(true);
  const [dataDoc, setDataDoc] = useState();
  const [inputChanged, setInputChanged] = useState(false);
  
  const parentAccounts = useSelector(
    (state) => state?.accounts?.parentAccounts
  );

  useEffect(() => {
    async function fetchData() {
      const response = await prismicClient().getSingle("contact_info_ria_2");
      setDataDoc(response);
    }
    fetchData();
  }, []);

  return dataDoc ? (
    <div className="contactBg">
      <div className="mw-1440 mx-auto d-flex flex-lg-row flex-column bg-gray">
        <div className="ria-contact-info-container">
          <div className="ria-contact-logo-container mx-auto text-center text-lg-start">
            <Link to="/">
              <img src={dataDoc.data?.logo?.url} alt="Brand Logo" />
            </Link>
          </div>
          <div className="info-container">
            <div className="previous-navigation  text-center text-lg-start">
              <span onClick={() => {
                if (inputChanged) {
                  dispatch({ type: dialogActionTypes.SHOW_DIALOG, value: { type: 'dialog_leave_contact', show: true, showSub: false, subType: null } });
                } else {
                  handleOptionClick("investor-type");
                  dispatch({
                    type: actionTypes.DELETE_ACCOUNT_TYPE
                  });
                }
              }}>
                &lt; Investor Type
              </span>
            </div>
            <div className="question-no-container">
              <p>{dataDoc.data?.action?.[0].text}</p>
            </div>
            <div className="info-heading-container text-center text-lg-start">
              <p>{dataDoc.data?.title?.[0].text}</p>
            </div>
            <div
              className={clsx("info-desc-container", {
                oneline: seeMore && isMobile(),
              })}
            >
              <RichText render={dataDoc.data?.description} />
            </div>
            <div className="info-desc-container text-center text-lg-start">
              {isMobile() && (
                <>
                  <span
                    className="see-more-btn d-md-none"
                    style={{ marginTop: "12px" }}
                    onClick={() => setSeeMore(!seeMore)}
                  >
                    {seeMore ? "+ See more" : "- See less"} <br />
                  </span>
                </>
              )}
            </div>
          </div>
        </div>
        
        <RIAContactInfoContent
            handleOptionClick={handleOptionClick}
            handleInputChanged={(changed) => setInputChanged(changed)} />

      </div>
    </div>
  ) : (
    <Loader />
  );
};

export default RIAContactInfo;
