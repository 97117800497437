import React, { useState, useEffect, useLayoutEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { navigate } from "gatsby";
import { client as prismicClient } from "../../../libs/prismic";
import Option from "../QualificationItem/Option";
import { Link } from "gatsby";
import Loader from "../../Loader";
import { actionTypes as qualActionTypes } from '../../../store/InvestorQualification/type';

const EntityStructure = ({ handleOptionClick }) => {
  const [dataDoc, setDataDoc] = useState();
  const dispatch = useDispatch();

  const parentAccounts = useSelector(
    (state) => state?.accounts?.parentAccounts
  );

  useEffect(() => {
    async function fetchData() {
      const response = await prismicClient().getSingle("entity_structure");
      setDataDoc(response);
    }
    fetchData();
  }, []);

  const handleOption = (value) => {
    if (parentAccounts.length === 0 || parentAccounts?.[0].type !== 'ria') {
      dispatch({
        type: qualActionTypes.UPDATE_ENTITY_STEP_ONE_FORM, value: {
          entityName: "",
          taxIdentificationType: "",
          signersTitle: "",
          zipcode: "",
        }
      })
      handleOptionClick(value)
    } else {
      if (value === 'entity-structure-s-corp') {
        dispatch({
          type: qualActionTypes.UPDATE_ENTITY_STEP_TWO_FORM, value: {
            entityName: "",
            addressLineOne: "",
            addressLineTwo: "",
            city: "",
            state: "",
            zipcode: "",
            phoneNo: "",
            extension: "",
            qualification: "",
            signersEmail: "",
            entityType: "",
            signersTitle: "",
            signersName: "",
          }
        })
        handleOptionClick('entity-contact-info')
      } else {
        dispatch({
          type: qualActionTypes.UPDATE_ENTITY_STEP_TWO_FORM, value: {
            entityName: "",
            entityType: "trust",
            addressLineOne: "",
            addressLineTwo: "",
            city: "",
            state: "",
            zipcode: "",
            phoneNo: "",
            extension: "",
            qualification: "",
            signersEmail: "",
            taxIdentificationType: "",
            signersTitle: "",
            signersName: "",
          }
        })
        handleOptionClick('trust-contact-info')
      }
    }

  }

  useLayoutEffect(() => {
    async function update(e) {
      
      handleOptionClick("investor-type");
      dispatch({
        type: qualActionTypes.DELETE_ACCOUNT_TYPE
      })
    }

    if (!window) {
      return;
    }

    window.addEventListener('popstate', update)
    return () => window.removeEventListener('popstate', update);
  })

  return dataDoc ? (
    <div className="contactBg">
      <div className="d-flex flex-lg-row flex-column text-center text-lg-start mw-1440 mx-auto bg-gray">
        <div className="entity-structure-info-container">
          <div className="entity-structure-logo-container mx-auto">
            <Link to="/">
              <img src={dataDoc.data?.logo?.url} alt="Brand Logo" />
            </Link>
          </div>
          <div className="info-container">
            <div className="previous-navigation">
              <span onClick={() => {
                handleOptionClick("investor-type");
                dispatch({
                  type: qualActionTypes.DELETE_ACCOUNT_TYPE
                })
              }}>
                &lt; {dataDoc.data?.action?.[0].text}
              </span>
            </div>
            <div className="info-heading-container">
              <p>{dataDoc.data?.title?.[0].text}</p>
            </div>
          </div>
        </div>
        <div className="entity-structure-options-container d-flex flex-column">
          {dataDoc.data?.formheader?.length > 0 && (
            <>
              <div className="choose-container">
                <p className="m-0">{dataDoc.data?.formheader?.[0]?.text}</p>
              </div>
              <div className="choose-hint-container">
                <p>{dataDoc.data?.formheader?.[1]?.text}</p>
              </div>
            </>
          )}
          {dataDoc.data?.questions?.map((item) => (
            <Option
              key={item?.keyword?.[0].keyword}
              option={item?.question}
              keyword={item?.keyword?.[0].text}
              handleClick={value => { handleOption(value) }}
            />
          ))}
        </div>
      </div>
    </div>
  ) : (
    <Loader />
  );
};

export default EntityStructure;
