import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import React, { useState, useEffect, useLayoutEffect } from "react";
import { connect, useSelector } from 'react-redux';
import { client as prismicClient } from "../../../libs/prismic";
import { RichText } from "prismic-reactjs";
import clsx from "clsx";
import { navigate } from "gatsby";
import { actionTypes } from '../../../store/InvestorQualification/type';
import { isMobile } from "../../../utils";
import { Link } from "gatsby";
import { useForm } from "../../../hooks/useForm";
import { isRequired, isText } from "../../../utils/validationFunc";
import TextInputField from "../../TextInputField";
import useSelectStyles from "../../../styles/components/Select";
import Loader from "../../Loader";

const EntityInfo = ({ handleOptionClick, updateFormData }) => {
  const classes = useSelectStyles();
  const [seeMore, setSeeMore] = useState(true);
  const [showError, setShowError] = useState(false);
  const [dataDoc, setDataDoc] = useState();

  const accountInfo = useSelector(
    (state) => state?.accounts?.newAccount?.accountInfo
  );

  const loading = useSelector(
    (state) => state?.investor_qualification?.isFormUpdateLoading
  );

  useEffect(() => {
    async function fetchData() {
      const response = await prismicClient().getSingle("entity_info");
      setDataDoc(response);
    }
    fetchData();
  }, [accountInfo]);

  const initialState = {
    entityName: "",
    entityType: "",
    signersTitle: "",
  };

  const validations = [
    ({ entityName }) =>
      isRequired(entityName) || {
        entityName: "This field is required",
      },
    ({ entityName }) =>
      isText(entityName) || {
        entityName: "Field can only contain letters A - Z, numbers 0 - 9, ampersand (&) and hyphen (-).",
      },
    ({ entityType }) =>
      isRequired(entityType) || {
        entityType: "This field is required",
      },
    ({ signersTitle }) =>
      isRequired(signersTitle) || {
        signersTitle: "This field is required",
      },
  ];

  const onSubmit = (formValues) => {
    updateFormData({ ...formValues, taxIdentificationType: formValues.entityType });
  };

  useEffect(() => {
    if (accountInfo) {
      initValues(accountInfo)
    }
  }, [accountInfo])


  useLayoutEffect(() => {
    async function update(e) {
      
      handleOptionClick("investor-type-entity")
    }

    if (!window) {
      return;
    }

    window.addEventListener('popstate', update)
    return () => window.removeEventListener('popstate', update);
  });

  const { inputValues, changeHandler, errors, isValid, submitHandler, initValues } =
    useForm(initialState, validations, onSubmit);

  return dataDoc ? (
    <div className="contactBg">
      <div className="mw-1440 mx-auto d-flex flex-lg-row flex-column text-center text-lg-start bg-gray">
        <div className="entity-info-container">
          <div className="entity-info-logo-container mx-auto">
            <Link to="/">
              <img src={dataDoc.data?.logo?.url} alt="Brand Logo" />
            </Link>
          </div>
          <div className="info-container">
            <div className="previous-navigation">
              <span onClick={() => handleOptionClick("investor-type-entity")}>
                &lt; {dataDoc.data?.action?.[0].text}
              </span>
            </div>
            <div className="info-heading-container">
              <p>{dataDoc.data?.title?.[0].text}</p>
            </div>
            <div
              className={clsx("info-desc-container", {
                oneline: seeMore && isMobile(),
              })}
            >
              <RichText render={dataDoc.data?.description} />
            </div>
            <div className="info-desc-container">
              {isMobile() && (
                <>
                  <span
                    className="see-more-btn d-md-none"
                    style={{ marginTop: "12px" }}
                    onClick={() => setSeeMore(!seeMore)}
                  >
                    {seeMore ? "+ See more" : "- See less"} <br />
                  </span>
                </>
              )}
            </div>
          </div>
        </div>
        <div className="entity-info-options-container d-flex flex-column">
          {loading && <div className="qualification-loading">
            <div className="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
          </div>}
          {dataDoc.data?.formheader?.length > 0 && (
            <>
              <div className="choose-container">
                <p className="m-0">{dataDoc.data?.formheader?.[0]?.text}</p>
              </div>
              <div className="choose-hint-container">
                <p>{dataDoc.data?.formheader?.[1]?.text}</p>
              </div>
            </>
          )}
          <div>
            <TextInputField
              label="Entity name"
              variant="filled"
              InputProps={{
                disableUnderline: true,
                style: {
                  borderColor: showError && errors.entityName && "#C75E5A",
                },
              }}
              onChange={changeHandler}
              name="entityName"
              value={inputValues.entityName}
              style={{ marginTop: 10, width: "100%" }}
              InputLabelProps={{
                style: {
                  color:
                    showError && errors.entityName
                      ? "#C75E5A"
                      : "rgba(0, 0, 0, 0.5)",
                },
              }}
            />
            {showError && errors.entityName && (
              <p className="error-message">{errors.entityName}</p>
            )}
          </div>
          <div>
            <FormControl
              className={classes.styleSelectFormControl}
              variant="filled"
            >
              <InputLabel
                className={classes.styleSelect}
                style={{
                  color: showError && errors.entityType && "#C75E5A",
                }}
              >
                Entity type
              </InputLabel>
              <Select
                onChange={changeHandler}
                className={classes.styleSelect}
                value={inputValues.entityType}
                name="entityType"
                style={{
                  borderColor: showError && errors.entityType && "#C75E5A",
                }}
              >
                {[
                  { value: "llc", text: "Limited Liability Company" },
                  { value: "sole_llc", text: "Sole Proprietor LLC" },
                  { value: "partnership", text: "Partnership" },
                  { value: "scorp", text: "S Corporation" },
                  { value: "ccorp", text: "C Corporation" },
                ].map(({ value, text }) => (
                  <MenuItem
                    key={value}
                    className={classes.styleSelectMenuItem}
                    value={value}
                  >
                    {text}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {showError && errors.entityType && (
              <p className="error-message">{errors.entityType}</p>
            )}
          </div>
          <div>
            <TextInputField
              label="Signer’s title"
              variant="filled"
              InputProps={{
                disableUnderline: true,
                style: {
                  borderColor: showError && errors.signersTitle && "#C75E5A",
                },
              }}
              onChange={changeHandler}
              name="signersTitle"
              value={inputValues.signersTitle}
              style={{ marginTop: 10, width: "100%" }}
              InputLabelProps={{
                style: {
                  color:
                    showError && errors.signersTitle
                      ? "#C75E5A"
                      : "rgba(0, 0, 0, 0.5)",
                },
              }}
            />
            {showError && errors.signersTitle && (
              <p className="error-message">{errors.signersTitle}</p>
            )}
          </div>
          <div className="continue-btn-container ">
            <Button
              onClick={() => {
                if (!isValid) {
                  setShowError(true);
                } else {
                  submitHandler();
                  handleOptionClick("entity-contact-info");
                }
              }}
            >
              Continue
            </Button>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <Loader />
  );
};


function mapDispatchToProps(dispatch) {
  return {
    updateFormData: (value) => {
      dispatch({ type: actionTypes.UPDATE_ENTITY_STEP_ONE_FORM, value })
    },
  }
}

export default connect(undefined, mapDispatchToProps)(EntityInfo);
