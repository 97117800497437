import React, { useState, useEffect } from "react";
import { connect, useSelector, useDispatch } from 'react-redux';
import { client as prismicClient } from "../../../libs/prismic";
import { Button } from "react-bootstrap";
import { isMobile } from "../../../utils";
import { useForm } from "../../../hooks/useForm";
import { isRequired } from "../../../utils/validationFunc";
import useSelectStyles from "../../../styles/components/Select";
import { RichText } from "prismic-reactjs";
import clsx from "clsx";
import { Link } from "gatsby";
import Loader from "../../Loader";
import TextInputField from "../../TextInputField";
import { actionTypes } from "../../../store/InvestorQualification/type";
import { actionTypes as dialogActionTypes } from "../../../store/Dialog/type";
import AccountService from "../../../services/AccountService";
import QuestionOption from "../QualificationItem/QuestionOption";
import SpinningProhibitionCertificationContent from "../SpinningProhibitionCertificationContent";

const accountService = new AccountService();

const SpinningProhibitionCertification = ({ handleOptionClick, updateFormData }) => {
  const dispatch = useDispatch();
  const [seeMore, setSeeMore] = useState(true);
  const [dataDoc, setDataDoc] = useState();
  const [inputChanged, setInputChanged] = useState(false);
  
  const newAccount = useSelector(
    (state) => state?.accounts?.newAccount
  );

  const parentAccounts = useSelector(
    (state) => state?.accounts?.parentAccounts
  );

  useEffect(() => {
    async function fetchData(mode) {
      const response = await prismicClient().getSingle(mode);
      setDataDoc(response);
    }

    let type = '';

    if (parentAccounts.length > 0 && parentAccounts[0].type === 'ria') {
      type = newAccount ? newAccount.type : parentAccounts[parentAccounts.length - 1].type;

      fetchData(`spinning_cert_ria_${type === 'sd-ira' ? 'sd_ira' : type}`);
    } else {
      type = newAccount.type;

      fetchData(`spinning_cert_${type === 'sd-ira' ? 'sd_ira' : type}`);
    }
  }, [parentAccounts]);

  return dataDoc ? (
    <div className="qualification-item-page contactBg">
      <div className="mw-1440 mx-auto d-flex flex-lg-row flex-column">
        <div className="qualification-info-container">
          <div className="qualification-logo-container mx-auto">
            <Link to="/">
              <img src={dataDoc.data?.logo?.url} alt="Brand Logo" />
            </Link>
          </div>
          <div className="previous-navigation text-center text-lg-start">
            <span onClick={() => {
              if (inputChanged) {
                dispatch({ type: dialogActionTypes.SHOW_DIALOG, value: { type: 'dialog_leave_contact', show: true, showSub: false, subType: null, backValue: "new-issues-certification", isDeleteAccount: false } });
              } else {
                handleOptionClick("new-issues-certification");
              }
            }}>
              &lt; New Issues Certification
            </span>
          </div>
          <div className="question-container">
            <div className="question-no-container">
              <p>{dataDoc.data?.action?.[0].text}</p>
            </div>
            <div className="question-container">
              <p>{dataDoc.data?.title?.[0].text}</p>
            </div>
            <div
              className={clsx("question-desc-container", {
                oneline: seeMore && isMobile(),
              })}
            >
              <RichText render={dataDoc.data?.description} />
            </div>
            <div className="question-desc-container">
              {isMobile() && (
                <>
                  <span
                    className="see-more-btn d-md-none"
                    style={{ marginTop: "12px" }}
                    onClick={() => setSeeMore(!seeMore)}
                  >
                    {seeMore ? "+ See more" : "- See less"} <br />
                  </span>
                </>
              )}
            </div>
          </div>
        </div>
        
        <SpinningProhibitionCertificationContent
            handleOptionClick={handleOptionClick}
            handleInputChanged={(changed) => setInputChanged(changed)} />

      </div>
    </div>
  ) : (
    <Loader />
  );
};

function mapDispatchToProps(dispatch) {
  return {
    updateFormData: (value) => {
      dispatch({ type: actionTypes.UPDATE_SPINNING_PROHIBITION_CERTIFICATION, value })
    },
  }
}

export default connect(undefined, mapDispatchToProps)(SpinningProhibitionCertification);
