import React, { useState, useEffect, useLayoutEffect } from "react";
import { connect, useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import { client as prismicClient } from "../../../libs/prismic";
import { isEmpty } from "lodash";
import { navigate } from "gatsby";
import { RichText } from "prismic-reactjs";
import clsx from "clsx";
import { actionTypes } from '../../../store/InvestorQualification/type';
import { Button } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import { isMobile } from "../../../utils";
import { Link } from "gatsby";
import { useForm } from "../../../hooks/useForm";
import {
  isRequired,
  isValidDate,
  isValidSSN,
} from "../../../utils/validationFunc";
import TextInputField from "../../TextInputField";
import { actionTypes as dialogActionTypes } from "../../../store/Dialog/type";
import Loader from "../../Loader";
import { moduleTypes } from "../../../store/type";
import AmlIdentityService from "../../../services/AmlIdentityService";
import AccountService from "../../../services/AccountService";

const amlIdentityService = new AmlIdentityService();
const accountService = new AccountService();

function delay(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

const IndividualVerification = ({ handleOptionClick, updateFormData, individualStepTwoForm }) => {
  const dispatch = useDispatch();
  const [seeMore, setSeeMore] = useState(true);
  const [termsChecked, setTermsChecked] = useState(false);
  const [showError, setShowError] = useState(false);
  const [showSsnExistError, setShowSsnExistError] = useState(false);
  const [showSsnSecondExistError, setShowSsnSecondExistError] = useState(false);
  const [dataDoc, setDataDoc] = useState();
  const [bottom, setBottom] = useState(false);
  const [isInputChanged, setIsInputChanged] = useState(false);

  const accountType = useSelector(
    (state) => state?.accounts?.newAccount?.type
  )

  const contactInfo = useSelector(
    (state) => state?.accounts?.newAccount?.contactInfo
  );

  const loading = useSelector(
    (state) => state?.investor_qualification?.isFormUpdateLoading
  );

  const newAccount = useSelector(
    (state) => state?.accounts?.newAccount
  );

  const parentAccounts = useSelector(
    (state) => state?.accounts?.parentAccounts
  );

  const profile = useSelector(
    (state) => state?.app?.profile
  );

  const initialState = {
    dateOfBirth: "",
    dateOfBirthSecond: "",
    ssn: "",
    ssnSecond: "",
    termsChecked: false,
  };

  useEffect(() => {
    async function fetchData() {
      const response = await prismicClient().getSingle(
        'verification_individual_2'
      );
      setDataDoc(response);
    }

    fetchData();
  }, []);

  const checkSsnValid = async (accountId, ssn, firstName, lastName) => {
    if (!ssn || !isValidSSN(ssn))
      return false;

    console.log("firstName, lastName", firstName, lastName);
    
    const result = await accountService.checkSsnUsable(accountId, ssn, firstName, lastName) 
    if (result && result.result) {
      return true;
    }

    return false;
  }

  const validations = [
    ({ dateOfBirth }) =>
      isRequired(dateOfBirth) || {
        dateOfBirth: "This field is required",
      },
    ({ dateOfBirth }) =>
      isValidDate(dateOfBirth) || {
        dateOfBirth: "Please enter a valid date of birth.",
      },
    ({ ssn }) =>
      isRequired(ssn) || {
        ssn: "This field is required",
      },
    ({ ssn }) =>
      isValidSSN(ssn) || {
        ssn: "Enter SSN in 123-45-6789 format ONLY.",
      },
    ({ dateOfBirthSecond }) =>
    isRequired(dateOfBirthSecond, accountType == "joint") || {
      dateOfBirthSecond: "This field is required",
      },
    ({ dateOfBirthSecond }) =>
      isValidDate(dateOfBirthSecond, accountType == "joint") || {
        dateOfBirthSecond: "Please enter a valid date of birth.",
      },
    ({ ssnSecond }) =>
      isRequired(ssnSecond, accountType == "joint") || {
        ssnSecond: "This field is required",
      },
    ({ ssnSecond }) =>
      isValidSSN(ssnSecond, accountType == "joint") || {
        ssnSecond: "Enter SSN in 123-45-6789 format ONLY.",
      },
  ];

  const handleScroll = (e) => {
    const _bottom = Math.abs(e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight) < 10;
    if (_bottom) {
      setTermsChecked(true);
      setBottom(true);
    }
  };

  const onSubmit = async (formValues) => {

  };

  let lastTitle = '';

  const doKYC = async () => {
    dispatch({ type: dialogActionTypes.SHOW_DIALOG, value: { type: 'dialog_pending_verification', show: true, payment: {title: `${contactInfo.legalFirstName} ${contactInfo.legalLastName}`} } });
    lastTitle = `${contactInfo.legalFirstName} ${contactInfo.legalLastName}`

    const accountId = newAccount ? newAccount._id : (parentAccounts.length > 0 ? parentAccounts[parentAccounts.length - 1]._id : "");

    if (!await checkSsnValid(accountId, inputValues.ssn)) {
      dispatch({ type: dialogActionTypes.SHOW_DIALOG, value: { type: 'dialog_pending_verification', show: false, payment: null } });
      setShowSsnExistError(true);
      return false;
    }

    const formData = {
      birthdate: moment(inputValues.dateOfBirth, "MM/DD/YYYY").format("YYYY-MM-DD"),
      firstName: contactInfo.legalFirstName,
      middleName: '',
      lastName: contactInfo.legalLastName,
      telephone: contactInfo.phoneNo.replace(/\((\d+)\)\s(\d+-\d+)/, '$1-$2'),
      addressStreet1: contactInfo.addressLineOne,
      addressStreet2: contactInfo.addressLineTwo,
      addressCity: contactInfo.city,
      addressState: contactInfo.state,
      addressZip: contactInfo.zipcode,
      ssn: inputValues.ssn,
      emailAddress: profile.email,
      countryCode: 'US',
      crdIndividual: '',
      crdFirm: '',
      refId: accountId,
    };

    // console.log("formData", formData);
    let info = null;
    const result = await amlIdentityService.sendIndividual(formData);
    if (result) {
      const { "reference-id": resReferenceId } = result;

      for (let i = 0; i < 3; i++) {
        if (i > 0) {
          await delay(5000);
        }

        info = await amlIdentityService.getInfo(resReferenceId);
        console.log('info', info);
        if (info != null) {
          if (info.data === 'created') {
            // console.log('Transaction has been created.');
          } else if (info.data === 'approved') {
            // console.log('Transaction has been approved: you can proceed with the user.');
            break;
          } else if (info.data === 'declined') {
            // console.log('Transaction has been declined: verification unsuccessful or user otherwise declined.');
            break;
          } else if (info.data === 'needs_review') {
            // console.log('Transaction is awaiting manual review (there was a report hit)');
            break;
          } else if (info.data === 'errored') {
            // console.log('Transaction has errored.');
            break;
          }
        }
      }
    }
    
    dispatch({ type: dialogActionTypes.SHOW_DIALOG, value: { type: 'dialog_pending_verification', show: false, payment: null } });

    if (info == null || (info.data === 'created' || info.data === 'approved' || info.data === 'needs_review')) {
      return true;
    } else {
      dispatch({ type: dialogActionTypes.SHOW_DIALOG, value: { type: 'dialog_failed_verification', show: true, payment: {error: "Name is valid"} } });      
      return false;
    }
  }

  const doKYCForJoint = async () => {
    dispatch({ type: dialogActionTypes.SHOW_DIALOG, value: { type: 'dialog_pending_verification', show: true, payment: {title: `${contactInfo.secondFirstName} ${contactInfo.secondLastName}`} } });
    lastTitle = `${contactInfo.secondFirstName} ${contactInfo.secondLastName}`

    const accountId = newAccount ? newAccount._id : (parentAccounts.length > 0 ? parentAccounts[parentAccounts.length - 1]._id : "");

    if (accountType == "joint" && !await checkSsnValid(accountId, inputValues.ssnSecond, contactInfo.secondFirstName, contactInfo.secondLastName)) {
      dispatch({ type: dialogActionTypes.SHOW_DIALOG, value: { type: 'dialog_pending_verification', show: false, payment: null } });
      setShowSsnSecondExistError(true);
      return false;
    }

    const formData = {
      birthdate: moment(inputValues.dateOfBirthSecond, "MM/DD/YYYY").format("YYYY-MM-DD"),
      firstName: contactInfo.secondFirstName,
      middleName: '',
      lastName: contactInfo.secondLastName,
      telephone: contactInfo.secondPhoneNo.replace(/\((\d+)\)\s(\d+-\d+)/, '$1-$2'),
      addressStreet1: '',
      addressStreet2: '',
      addressCity: '',
      addressState: '',
      addressZip: '',
      ssn: inputValues.ssnSecond,
      emailAddress: contactInfo.secondEmail,
      countryCode: 'US',
      crdIndividual: '',
      crdFirm: '',
      refId: `${accountId}_C`,
    };

    // console.log("formData", formData);
    let info = null;
    const result = await amlIdentityService.sendIndividual(formData);
    if (result) {
      const { "reference-id": resReferenceId } = result;

      for (let i = 0; i < 3; i++) {
        if (i > 0) {
          await delay(5000);
        }

        info = await amlIdentityService.getInfo(resReferenceId);
        console.log('info', info);
        if (info != null) {
          if (info.data === 'created') {
            // console.log('Transaction has been created.');
          } else if (info.data === 'approved') {
            // console.log('Transaction has been approved: you can proceed with the user.');
            break;
          } else if (info.data === 'declined') {
            // console.log('Transaction has been declined: verification unsuccessful or user otherwise declined.');
            break;
          } else if (info.data === 'needs_review') {
            // console.log('Transaction is awaiting manual review (there was a report hit)');
            break;
          } else if (info.data === 'errored') {
            // console.log('Transaction has errored.');
            break;
          }
        }
      }
    }

    dispatch({ type: dialogActionTypes.SHOW_DIALOG, value: { type: 'dialog_pending_verification', show: false, payment: null } });

    if (info == null || (info.data === 'created' || info.data === 'approved' || info.data === 'needs_review')) {
      return true;
    } else {
      dispatch({ type: dialogActionTypes.SHOW_DIALOG, value: { type: 'dialog_failed_verification', show: true, payment: {error: "Name is valid"} } });      
      return false;
    }
  }

  const handleSubmit = async () => {

    let retKYC = await doKYC();
    if (!retKYC) return;

    if (accountType === 'joint') {
      let retKYC2 = await doKYCForJoint();
      if (!retKYC2) return;
    }

    dispatch({ type: dialogActionTypes.SHOW_DIALOG, value: { type: 'dialog_pending_verification', show: true, payment: {title: lastTitle, content: 'Finishing up...'} } });    
    updateFormData(inputValues);
  }

  const changeInputHandler = (event) => {
    changeHandler(event);
    setIsInputChanged(true);
  }

  useLayoutEffect(() => {
    async function update(e) {

      handleOptionClick("spinning-prohibition-certification")
    }

    if (!window) {
      return;
    }

    window.addEventListener('popstate', update)
    return () => window.removeEventListener('popstate', update);
  })
  const { inputValues, changeHandler, changeKeyHandler, errors, isValid, submitHandler, initValues, secrets, secretsSecond } =
    useForm(initialState, validations, onSubmit);

  return dataDoc ? (
    <div className="contactBg">
      <div className="mw-1440 mx-auto d-flex flex-lg-row flex-column bg-gray">
        <div className="individual-contact-info-container">
          <div className="individual-contact-logo-container mx-auto  text-center text-lg-start">
            <Link to="/invest-trade/">
              <img src={dataDoc.data?.logo?.url} alt="Brand Logo" />
            </Link>
          </div>
          <div className="info-container">
            <div className="previous-navigation text-center text-lg-start">
              <span onClick={() => {
                if (isInputChanged) {
                  dispatch({ type: dialogActionTypes.SHOW_DIALOG, value: { type: 'dialog_leave_contact', show: true, showSub: false, subType: null, backValue: "spinning-prohibition-certification", isDeleteAccount: false } });
                } else {
                  handleOptionClick("spinning-prohibition-certification");
                }
              }}>
                &lt; Spinning Prohibition Certification
              </span>
            </div>
            <div className="question-no-container">
              <p>{dataDoc.data?.action?.[0].text}</p>
            </div>
            <div className="info-heading-container  text-center text-lg-start">
              <p>{dataDoc.data?.title?.[0].text}</p>
            </div>
            <div
              className={clsx("info-desc-container", {
                oneline: seeMore && isMobile(),
              })}
            >
              <RichText render={dataDoc.data?.description} />
            </div>
            <div className="info-desc-container text-center text-lg-start">
              {isMobile() && (
                <>
                  <span
                    className="see-more-btn d-md-none"
                    style={{ marginTop: "12px" }}
                    onClick={() => setSeeMore(!seeMore)}
                  >
                    {seeMore ? "+ See more" : "- See less"} <br />
                  </span>
                </>
              )}
            </div>
          </div>
        </div>
        <div className="individual-verification-options-container d-flex flex-column m-t-10">
          {loading && <div className="qualification-loading">
            <div className="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
          </div>}
          {!isEmpty(dataDoc.data?.formheader?.[0]?.text) && (
            <>
              <div className="choose-container">
                <p className="m-0">{dataDoc.data?.formheader?.[0]?.text}</p>
              </div>
              <div className="choose-hint-container">
                <p>{dataDoc.data?.formheader?.[1]?.text}</p>
              </div>
            </>
          )}
          <div className="ml-20">
            <b>{`${contactInfo?.legalFirstName} ${contactInfo?.legalLastName}`}</b>
            <p className="mb-0">{newAccount?.type === "joint" ? "Joint investor(primary)" : "Individual Investor"}</p>
          </div>
          <div>
            <TextInputField
              label="Date of birth"
              placeholder="MM/DD/YYYY"
              variant="filled"
              autoComplete="off"
              InputProps={{
                disableUnderline: true,
                style: {
                  borderColor: showError && errors.dateOfBirth && "#C75E5A",
                },
              }}
              inputProps={{
                maxLength: 10,
              }}
              onChange={changeInputHandler}
              name="dateOfBirth"
              value={inputValues.dateOfBirth}
              style={{ marginTop: 10, width: "100%" }}
              InputLabelProps={{
                style: {
                  color:
                    showError && errors.dateOfBirth
                      ? "#C75E5A"
                      : "rgba(0, 0, 0, 0.5)",
                },
              }}
            />
            {showError && errors.dateOfBirth && (
              <p className="error-message">{errors.dateOfBirth}</p>
            )}
          </div>
          <div className="mb-20">
            <TextInputField
              label="Social security number"
              placeholder="123-45-6789"
              variant="filled"
              autoComplete="off"
              InputProps={{
                disableUnderline: true,
                style: {
                  borderColor:
                    (showSsnExistError || (showError && errors.ssn)) && "#C75E5A",
                },
              }}
              onChange={(e) => {setShowSsnExistError(false); changeInputHandler(e);}}
              onKeyDown={changeKeyHandler}
              name="ssn"
              value={secrets}
              style={{ marginTop: 10, width: "100%" }}
              InputLabelProps={{
                style: {
                  color:
                    (showSsnExistError || (showError && errors.ssn))
                      ? "#C75E5A"
                      : "rgba(0, 0, 0, 0.5)",
                },
              }}
            />
            {showSsnExistError && (
              <p className="error-message">There is an established Individual account with the same SSN. Try again or contact Support for assistance.</p>
            )}
            {!showSsnExistError && showError && errors.ssn && (
              <p className="error-message">{errors.ssn}</p>
            )}
          </div>
          {accountType == "joint" && (
            <div>
              <div className="ml-20">
                <b>{`${contactInfo?.secondFirstName} ${contactInfo?.secondLastName}`}</b>
                <p className="mb-0">Joint investor(secondary)</p>
              </div>
              <div>
                <TextInputField
                  label="Date of birth"
                  placeholder="MM/DD/YYYY"
                  variant="filled"
                  autoComplete="off"
                  InputProps={{
                    disableUnderline: true,
                    style: {
                      borderColor: showError && errors.dateOfBirthSecond && "#C75E5A",
                    },
                  }}
                  inputProps={{
                    maxLength: 10,
                  }}
                  onChange={changeInputHandler}
                  name="dateOfBirthSecond"
                  value={inputValues.dateOfBirthSecond}
                  style={{ marginTop: 10, width: "100%" }}
                  InputLabelProps={{
                    style: {
                      color:
                        showError && errors.dateOfBirthSecond
                          ? "#C75E5A"
                          : "rgba(0, 0, 0, 0.5)",
                    },
                  }}
                />
                {showError && errors.dateOfBirthSecond && (
                  <p className="error-message">{errors.dateOfBirthSecond}</p>
                )}
              </div>
              <div>
                <TextInputField
                  label="Social security number"
                  placeholder="123-45-6789"
                  variant="filled"
                  autoComplete="off"
                  InputProps={{
                    disableUnderline: true,
                    style: {
                      borderColor:
                      (showSsnSecondExistError || (showError && errors.ssnSecond)) && "#C75E5A",
                    },
                  }}
                  onChange={(e) => {setShowSsnSecondExistError(false); changeInputHandler(e);}}
                  onKeyDown={changeKeyHandler}
                  name="ssnSecond"
                  value={secretsSecond}
                  style={{ marginTop: 10, width: "100%" }}
                  InputLabelProps={{
                    style: {
                      color:
                        (showSsnSecondExistError || (showError && errors.ssnSecond))
                          ? "#C75E5A"
                          : "rgba(0, 0, 0, 0.5)",
                    },
                  }}
                />
                {showSsnSecondExistError && (
                  <p className="error-message">There is an established Individual account with the same SSN. Try again or contact Support for assistance.</p>
                )}
                {!showSsnSecondExistError && showError && errors.ssnSecond && (
                  <p className="error-message">{errors.ssnSecond}</p>
                )}
              </div>
            </div>
          )}
          <div className="individual-terms-and-privacy d-flex">
            <div className="checkbox">
              <Checkbox
                checked={termsChecked}
                name="termsChecked"
                onChange={(event) => setTermsChecked(event.target.checked)}
                sx={{ "&.MuiCheckbox-root": { padding: 0 } }}
                disabled={!bottom}
              />
            </div>
            <div
              className="term-privacy-message"
              style={{
                borderColor: showError && !termsChecked && "#C75E5A",
              }}
              onScroll={handleScroll}
            >
              <RichText render={dataDoc.data?.terms} />
            </div>
          </div>
          {showError && !termsChecked && (
            <p className="error-message" style={{ marginLeft: 12 }}>
              Please accept Opalvest's Terms of Service and Privacy Policy.
            </p>
          )}

          <div className="continue-btn-container text-center">
            <Button
              disabled={!termsChecked}
              onClick={() => {
                if (!isValid) {
                  setShowError(true);
                } else {
                  //submitHandler();
                  handleSubmit();
                  setIsInputChanged(false);
                }
              }}
            >
              Submit
            </Button>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <Loader />
  );
};

const mapStateToProps = (state) => {
  return {
    individualStepTwoForm: state[moduleTypes.INVESTOR_QUALIFICATION].individualStepTwoForm,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    updateFormData: (value) => {
      dispatch({ type: actionTypes.UPDATE_INDIVIDUAL_STEP_TWO_FORM, value })
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(IndividualVerification);
