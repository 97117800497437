import React, { useState, useEffect, useLayoutEffect } from "react";
import { useSelector } from "react-redux";
import { Link, navigate } from "gatsby";
import { client as prismicClient } from "../../../libs/prismic";
import { isMobile } from "../../../utils";
import { RichText } from "prismic-reactjs";
import clsx from "clsx";
import Option from "../QualificationItem/Option";
import Loader from "../../Loader";
import { actionTypes } from "../../../store/InvestorQualification/type";
import { useDispatch } from "react-redux";
import { ACCOUNT_TYPE } from "../../../Constants";

const IndividualType = ({ handleOptionClick }) => {
  const [seeMore, setSeeMore] = useState(true);
  const [dataDoc, setDataDoc] = useState();
  const dispatch = useDispatch();

  const accounts = useSelector((state) => state?.accounts?.parentAccounts);

  const loading = useSelector(
    (state) => state?.investor_qualification?.isFormUpdateLoading
  );

  useEffect(() => {
    async function fetchData(mode) {
      const response = await prismicClient().getSingle(mode);
      setDataDoc(response);
    }

    if (accounts.length > 0 && accounts?.[0]?.type === "ria" && accounts?.[0]?.dateOfBirth) {
      fetchData("individual_type_ria_sub_2")
    } else {
      fetchData("individual_type_2");
    }

    // if (accounts?.filter(el => el.hasOwnProperty('zipcode')).length > 0) {
    //   fetchData('sub_investor_type');
    // } else {
    //   fetchData('investor_type_2');
    // }
  }, [accounts]);
  
  const individualOptionClick = (e) => {
    let individualType = "";
    if (e === "individual-type-individual") {
      individualType = ACCOUNT_TYPE.INDIVIDUAL;
      handleOptionClick('investor-type-individual');
    } else if (e === "individual-type-joint") {
      individualType = ACCOUNT_TYPE.INDIVIDUAL_JOINT;
      handleOptionClick('investor-type-joint');
    } else if (e === "individual-type-sd-ira") {
      individualType = ACCOUNT_TYPE.INDIVIDUAL_SD_IRA;
      handleOptionClick('investor-type-sd-ira');
    }

    dispatch({ type: actionTypes.CREATE_ACCOUNT_TYPE, value: individualType });
  };

  useLayoutEffect(() => {
    function update(e) {
      e.preventDefault();
      navigate("/");
    }

    if (!window) {
      return;
    }

    window.addEventListener("popstate", update);
    return () => window.removeEventListener("popstate", update);
  });
  return dataDoc ? (
    <div className="investorType">
      <div className="mw-1440 mx-auto d-flex flex-lg-row flex-column">
        <div className="investor-type-info-container text-center text-lg-start">
          <div className="investor-type-logo-container mx-auto">
            <Link to="/">
              <img src={dataDoc.data?.logo?.url} alt="Brand Logo" />
            </Link>
          </div>
          <div className="info-container">
            <div className="previous-navigation">
              <span onClick={() => {
                handleOptionClick("investor-type");
                dispatch({
                  type: actionTypes.DELETE_ACCOUNT_TYPE
                })
              }}>
                &lt; {dataDoc.data?.action?.[0].text}
              </span>
            </div>
          </div>
          <div className="question-container">
            <div className="question-container">
              <p>{dataDoc.data?.title?.[0].text}</p>
            </div>
            <div
              className={clsx("question-desc-container", {
                oneline: seeMore && isMobile(),
              })}
            >
              <RichText render={dataDoc.data?.description} />
            </div>
            <div className="question-desc-container">
              {isMobile() && (
                <>
                  <span
                    className="see-more-btn d-md-none"
                    style={{ marginTop: "12px" }}
                    onClick={() => setSeeMore(!seeMore)}
                  >
                    {seeMore ? "+ See more" : "- See less"} <br />
                  </span>
                </>
              )}
            </div>
          </div>
        </div>
        <div className="investor-type-options-container">
          {dataDoc.data?.formheader?.length > 0 && (
            <>
              <div className="choose-container">
                <p className="m-0">{dataDoc.data?.formheader?.[0]?.text}</p>
              </div>
              <div className="choose-hint-container">
                <p>{dataDoc.data?.formheader?.[1]?.text}</p>
              </div>
            </>
          )}
          {dataDoc.data?.questions
            ?.filter(({ hidden = undefined, keyword }) => !hidden)
            ?.map((item) => (
              <Option
                key={item?.keyword?.[0].text}
                option={item?.question}
                keyword={item?.keyword?.[0].text}
                disabled={loading}
                handleClick={(e) => {
                  individualOptionClick(e);
                }}
              />
            ))}
          <br />
          <br />
        </div>
      </div>
    </div>
  ) : (
    <Loader />
  );
};

export default IndividualType;
