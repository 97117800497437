import React, { useState, useEffect, useLayoutEffect } from "react";
import { useSelector } from "react-redux";
import { Link, navigate } from "gatsby";
import { client as prismicClient } from "../../../libs/prismic";
import { isMobile } from "../../../utils";
import { RichText } from "prismic-reactjs";
import clsx from "clsx";
import Option from "../QualificationItem/Option";
import Loader from "../../Loader";
import { actionTypes } from '../../../store/InvestorQualification/type';
import { useDispatch } from "react-redux";
import { ACCOUNT_TYPE } from "../../../Constants"

const InvestorType = ({ handleOptionClick }) => {
  const [seeMore, setSeeMore] = useState(true);
  const [dataDoc, setDataDoc] = useState();
  const dispatch = useDispatch();

  const accounts = useSelector(
    (state) => state?.accounts?.parentAccounts
  );

  const loading = useSelector(
    (state) => state?.investor_qualification?.isFormUpdateLoading
  );

  const invitationInfo = useSelector(
    (state) => state?.app.invitation
  )

  const activeStep = useSelector(
    (state) => state?.investor_qualification?.activeStep
  );

  useEffect(() => {
    async function fetchData(mode) {
      const response = await prismicClient().getSingle(mode);
      setDataDoc(response);
    }

    if (invitationInfo?.accountId) {
      handleOptionClick('investor-type-ria');
      dispatch({ type: actionTypes.CREATE_ACCOUNT_TYPE, value: ACCOUNT_TYPE.RIA })
    }

    if (accounts.length > 0 && accounts[0].type === 'ria') {
      fetchData('investor_type_ria_sub_2');
    } else {
      fetchData('investor_type_2');
    }
  }, [accounts]);

  const investOptionClick = (e) => {
    let accountType = ""
    if (e === 'investor-type-individual') {
      dispatch({ type: actionTypes.UPDATE_ACTIVE_STEP, value: 'individual-type' });
      navigate('/investor-qualification?step=individual-type');
    } else {
      if (e === 'investor-type-business') {
        accountType = ACCOUNT_TYPE.BUSINESS;
        handleOptionClick('investor-type-business');
      } else if (e === 'investor-type-trust') {
        accountType = ACCOUNT_TYPE.TRUST;
        handleOptionClick('investor-type-trust');
      } else if (e === 'investor-type-ria') {
        accountType = ACCOUNT_TYPE.RIA;
        handleOptionClick('investor-type-ria');
      }

      dispatch({ type: actionTypes.CREATE_ACCOUNT_TYPE, value: accountType })
    }
  }

  useLayoutEffect(() => {
    function update(e) {
      e.preventDefault()
      navigate('/')
    }

    if (!window) {
      return;
    }

    window.addEventListener('popstate', update)
    return () => window.removeEventListener('popstate', update);
  })
  return dataDoc ? (
    <div className="investorType">
      <div className="mw-1440 mx-auto d-flex flex-lg-row flex-column">
        <div className="investor-type-info-container text-center text-lg-start">
          <div className="investor-type-logo-container mx-auto">
            <Link to="/">
              <img src={dataDoc.data?.logo?.url} alt="Brand Logo" />
            </Link>
          </div>
          <div className="question-container">
            <div className="question-type-container">
              <p>{dataDoc.data?.action?.[0].text}</p>
            </div>
            <div className="question-container">
              <p>{dataDoc.data?.title?.[0].text}</p>
            </div>
            <div
              className={clsx("question-desc-container", {
                oneline: seeMore && isMobile(),
              })}
            >
              <RichText render={dataDoc.data?.description} />
            </div>
            <div className="question-desc-container">
              {isMobile() && (
                <>
                  <span
                    className="see-more-btn d-md-none"
                    style={{ marginTop: "12px" }}
                    onClick={() => setSeeMore(!seeMore)}
                  >
                    {seeMore ? "+ See more" : "- See less"} <br />
                  </span>
                </>
              )}
            </div>
          </div>
        </div>
        <div className="investor-type-options-container">
          {dataDoc.data?.formheader?.length > 0 && (
            <>
              <div className="choose-container">
                <p className="m-0">{dataDoc.data?.formheader?.[0]?.text}</p>
              </div>
              <div className="choose-hint-container">
                <p>{dataDoc.data?.formheader?.[1]?.text}</p>
              </div>
            </>
          )}
          {(accounts.length > 0 && accounts[0].type !== 'ria') ? 
          dataDoc.data?.questions?.filter(({ hidden = undefined, keyword }) => !hidden && keyword?.[0].text !== 'investor-type-ria')?.map((item) => (
            <Option
              key={item?.keyword?.[0].text}
              option={item?.question}
              keyword={item?.keyword?.[0].text}
              disabled={loading}
              handleClick={(e) => {
                investOptionClick(e);
              }}
            />
          )) : dataDoc.data?.questions?.filter(({ hidden = undefined, keyword }) => !hidden)?.map((item) => (
            <Option
              key={item?.keyword?.[0].text}
              option={item?.question}
              keyword={item?.keyword?.[0].text}
              disabled={loading}
              handleClick={(e) => {
                investOptionClick(e);
              }}
            />
          ))}
          <br />
          <br />
        </div>
      </div>
    </div>
  ) : (
    <Loader />
  );
};

export default InvestorType;
