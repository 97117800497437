import React, { useState, useEffect } from "react";
import { connect, useSelector, useDispatch } from 'react-redux';
import { client as prismicClient } from "../../../libs/prismic";
import { isMobile } from "../../../utils";
import { RichText } from "prismic-reactjs";
import clsx from "clsx";
import { Link } from "gatsby";
import Loader from "../../Loader";
import { actionTypes } from "../../../store/InvestorQualification/type";
import { actionTypes as dialogActionTypes } from "../../../store/Dialog/type";
import BusinessQuestionContent from "../BusinessQuestionContent";

const BusinessQuestion = ({ handleOptionClick }) => {
  const dispatch = useDispatch();
  const [seeMore, setSeeMore] = useState(true);
  const [dataDoc, setDataDoc] = useState();
  const [inputChanged, setInputChanged] = useState(false);
 
  const newAccount = useSelector(
    (state) => state?.accounts?.newAccount
  );
  
  const parentAccounts = useSelector(
    (state) => state?.accounts?.parentAccounts
  );

  useEffect(() => {
    async function fetchData(mode) {
      const response = await prismicClient().getSingle(mode);
      setDataDoc(response);
    }

    if (parentAccounts.length > 0 && parentAccounts[0].type === 'ria') {
      fetchData('investor_questions_ria_business');
    } else {
      fetchData('investor_questions_business');
    }
  }, [parentAccounts]);


  return dataDoc ? (
    <div className="qualification-item-page contactBg">
      <div className="mw-1440 mx-auto d-flex flex-lg-row flex-column">
        <div className="qualification-info-container">
          <div className="qualification-logo-container mx-auto">
            <Link to="/">
              <img src={dataDoc.data?.logo?.url} alt="Brand Logo" />
            </Link>
          </div>
          <div className="previous-navigation text-center text-lg-start">
            <span onClick={() => {
              if (inputChanged) {
                dispatch({ type: dialogActionTypes.SHOW_DIALOG, value: { type: 'dialog_leave_contact', show: true, showSub: false, subType: null, backValue: newAccount?.type === "business" ? "qualification-business-two" : "qualification-trust-two", isDeleteAccount: false } });
              } else {
                handleOptionClick(newAccount?.type === "business" ? "qualification-business-two" : "qualification-trust-two");
              }
            }}>
              &lt; Investor Qualification 2 of 2
            </span>
          </div>
          <div className="question-container">
            <div className="question-no-container">
              <p>{dataDoc.data?.action?.[0].text}</p>
            </div>
            <div className="question-container">
              <p>{dataDoc.data?.title?.[0].text}</p>
            </div>
            <div
              className={clsx("question-desc-container", {
                oneline: seeMore && isMobile(),
              })}
            >
              <RichText render={dataDoc.data?.description} />
            </div>
            <div className="question-desc-container">
              {isMobile() && (
                <>
                  <span
                    className="see-more-btn d-md-none"
                    style={{ marginTop: "12px" }}
                    onClick={() => setSeeMore(!seeMore)}
                  >
                    {seeMore ? "+ See more" : "- See less"} <br />
                  </span>
                </>
              )}
            </div>
          </div>
        </div>
        
        <BusinessQuestionContent 
            handleOptionClick = {handleOptionClick} 
            handleInputChanged={(changed) => setInputChanged(changed)} />

      </div>
    </div>
  ) : (
    <Loader />
  );
};

export default BusinessQuestion;
