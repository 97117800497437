import React from "react";
import clsx from "clsx";
import { RichText } from "prismic-reactjs";

const Option = ({ option, keyword, handleClick, disabled = false }) => {
  return (
    <div
      role="button"
      style={{
        backgroundColor: keyword === "investor-type-ria" ? "#3399CC" : "transparent",
        color: keyword === "investor-type-ria" ? "white" : "black",
      }}
      className={clsx("investor-type-option text-center", { "text-gray": disabled })}
      onClick={() => {
        if (disabled) return;
        handleClick(keyword);
      }}
    >
      <p className="m-0">
        <RichText render={option} />
      </p>
    </div>
  );
};

export default Option;
