import { Button } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import moment from 'moment';
import React, { useState, useEffect, useLayoutEffect } from "react";
import { connect, useSelector, useDispatch } from 'react-redux';
import { Link, navigate } from "gatsby";
import { client as prismicClient } from "../../../libs/prismic";
import { RichText } from "prismic-reactjs";
import clsx from "clsx";
import { actionTypes } from '../../../store/InvestorQualification/type';
import { isMobile } from "../../../utils";
import { actionTypes as dialogActionTypes } from "../../../store/Dialog/type";

import { useForm } from "../../../hooks/useForm";
import {
  isRequired,
  isValidDate,
  isValidEIN,
  isValidSSN,
} from "../../../utils/validationFunc";
import TextInputField from "../../TextInputField";
import Loader from "../../Loader";
import AmlIdentityService from "../../../services/AmlIdentityService";
import AccountService from "../../../services/AccountService";

const amlIdentityService = new AmlIdentityService();
const accountService = new AccountService();

function delay(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

const TrustVerification = ({ handleOptionClick, updateFormData }) => {
  const dispatch = useDispatch();
  const [seeMore, setSeeMore] = useState(true);
  const [termsChecked, setTermsChecked] = useState(false);
  const [showError, setShowError] = useState(false);
  const [showUserSsnExistError, setShowUserSsnExistError] = useState(false);
  const [showEinExistError, setShowEinExistError] = useState(false);
  const [dataDoc, setDataDoc] = useState();
  const [bottom, setBottom] = useState(false);

  const newAccount = useSelector(
    (state) => state?.accounts?.newAccount
  );

  const contactInfo = useSelector(
    (state) => state?.accounts?.newAccount?.contactInfo
  );

  const profile = useSelector(
    (state) => state?.app?.profile
  );

  const loading = useSelector(
    (state) => state?.investor_qualification?.isFormUpdateLoading
  );

  const parentAccounts = useSelector(
    (state) => state?.accounts?.parentAccounts
  );

  const checkSsnValid = async (accountId, ssn, firstName, lastName) => {
    if (!ssn || !isValidSSN(ssn))
      return false;
    
    const result = await accountService.checkSsnUsable(accountId, ssn, firstName, lastName) 
    if (result && result.result) {
      return true;
    }

    return false;
  }

  const checkEinValid = async (accountId, ein, companyName) => {
    if (!ein || !isValidEIN(ein))
      return false;
    
    const result = await accountService.checkEinUsable(accountId, ein, companyName) 
    if (result && result.result) {
      return true;
    }

    return false;
  }

  const initialState = {
    dateOfBirth: "",
    userSsn: "",
    dateOfIncorporation: "",
    ein: "",
    ssn: "",
    termsChecked: false,
  };

  useEffect(() => {
    async function fetchData(mode) {
      const response = await prismicClient().getSingle(`${mode}`);
      setDataDoc(response);
    }
    
    fetchData("verification_trust_2")
  }, [parentAccounts]);

  const validations = [
    ({ dateOfBirth }) =>
      isRequired(dateOfBirth) || {
        dateOfBirth: "This field is required",
      },
    ({ dateOfBirth }) =>
      isValidDate(dateOfBirth) || {
        dateOfBirth: "Enter date in MM/DD/YYYY format ONLY.",
      },
    ({ userSsn }) =>
      isRequired(userSsn) || {
        userSsn: "This field is required",
      },
    ({ userSsn }) =>
      isValidSSN(userSsn) || {
        userSsn: "SSN in 123-45-6789 format ONLY.",
      },
    ({ dateOfIncorporation }) =>
      isRequired(dateOfIncorporation) || {
        dateOfIncorporation: "This field is required",
      },
    ({ dateOfIncorporation }) =>
      isValidDate(dateOfIncorporation) || {
        dateOfIncorporation: "Enter date in MM/DD/YYYY format ONLY.",
      },
    ({ ein }) =>
      isRequired(ein, newAccount.contactInfo.taxIdentificationType === 'EIN') || {
        ein: "This field is required",
      },
    ({ ein }) =>
      isValidEIN(ein, newAccount.contactInfo.taxIdentificationType === 'EIN') || {
        ein: "EIN in 12-3456789 format ONLY.",
      },
    ({ ssn }) =>
      isRequired(ssn, newAccount.contactInfo.taxIdentificationType === 'SSN') || {
        ssn: "This field is required",
      },
    ({ ssn }) =>
      isValidSSN(ssn, newAccount.contactInfo.taxIdentificationType === 'SSN') || {
        ssn: "SSN in 123-45-6789 format ONLY.",
      },
  ];

  const handleScroll = (e) => {
    const _bottom = Math.abs(e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight) < 10;
    if (_bottom) {
      setTermsChecked(true);
      setBottom(true);
    }
  };

  const onSubmit = (formValues) => {
    // updateFormData({ ...formValues, termsChecked });
  };

  const doKYB = async () => {
    dispatch({ type: dialogActionTypes.SHOW_DIALOG, value: { type: 'dialog_pending_verification', show: true, payment: {title: contactInfo.entityName} } });

    const accountId = newAccount ? newAccount._id : (parentAccounts.length > 0 ? parentAccounts[parentAccounts.length - 1]._id : "");

    if (newAccount.contactInfo.taxIdentificationType === 'EIN') {
      if (!await checkEinValid(accountId, inputValues.ein)) {
        dispatch({ type: dialogActionTypes.SHOW_DIALOG, value: { type: 'dialog_pending_verification', show: false, payment: null } });
        setShowEinExistError(true);
        return false;
      }
    } else {
      if (!await checkSsnValid(accountId, inputValues.ssn)) {
        dispatch({ type: dialogActionTypes.SHOW_DIALOG, value: { type: 'dialog_pending_verification', show: false, payment: null } });
        setShowEinExistError(true);
        return false;
      }
    }

    const formData = {
      businessName: contactInfo.entityName,
      businessTaxIdentificationNumber: inputValues.ein,
      businessPhysicalAddress: {
        street1: contactInfo.mailChecked == 1 ? contactInfo.mailAddressLineOne : contactInfo.addressLineOne,
        street2: contactInfo.mailChecked == 1 ? contactInfo.mailAddressLineTwo : contactInfo.addressLineTwo,
        city: contactInfo.mailChecked == 1 ? contactInfo.mailCity : contactInfo.city,
        subdivision: contactInfo.mailChecked == 1 ? contactInfo.mailState : contactInfo.state,
        postalCode: contactInfo.mailChecked == 1 ? contactInfo.mailZipcode : contactInfo.zipcode,
        countryCode: 'US',
      },
      businessRegisteredAddress: {
        street1: contactInfo.addressLineOne,
        street2: contactInfo.addressLineTwo,
        city: contactInfo.city,
        subdivision: contactInfo.state,
        postalCode: contactInfo.zipcode,
        countryCode: 'US',
      },
      crdIndividual: "",
      crdFirm: "",
      refId: accountId,
    };

    console.log("Business formData", formData);
    let info = null;
    const result = await amlIdentityService.sendBusiness(formData);
    if (result) {
      const { "reference-id": resReferenceId } = result;

      for (let i = 0; i < 3; i++) {
        if (i > 0) {
          await delay(5000);
        }

        info = await amlIdentityService.getInfo(resReferenceId);
        console.log('info', info);
        if (info != null) {
          if (info.data === 'created') {
            // console.log('Transaction has been created.');
          } else if (info.data === 'approved') {
            // console.log('Transaction has been approved: you can proceed with the user.');
            break;
          } else if (info.data === 'declined') {
            // console.log('Transaction has been declined: verification unsuccessful or user otherwise declined.');
            break;
          } else if (info.data === 'needs_review') {
            // console.log('Transaction is awaiting manual review (there was a report hit)');
            break;
          } else if (info.data === 'errored') {
            // console.log('Transaction has errored.');
            break;
          }
        }
      }
    }
    
    dispatch({ type: dialogActionTypes.SHOW_DIALOG, value: { type: 'dialog_pending_verification', show: false, payment: null } });

    if (info === null || (info.data === 'created' || info.data === 'approved' || info.data === 'needs_review')) {
      return true;
    } else {
      dispatch({ type: dialogActionTypes.SHOW_DIALOG, value: { type: 'dialog_failed_verification', show: true, payment: {error: "Name is valid"} } });
      return false;
    }
  }

  const doKYC = async () => {
    dispatch({ type: dialogActionTypes.SHOW_DIALOG, value: { type: 'dialog_pending_verification', show: true, payment: {title: `${contactInfo.legalFirstName} ${contactInfo.legalLastName}`} } });

    const accountId = newAccount ? newAccount._id : (parentAccounts.length > 0 ? parentAccounts[parentAccounts.length - 1]._id : "");
    if (!await checkSsnValid(accountId, inputValues.userSsn)) {
      dispatch({ type: dialogActionTypes.SHOW_DIALOG, value: { type: 'dialog_pending_verification', show: false, payment: null } });
      setShowUserSsnExistError(true);
      return false;
    }

    const formData = {
      birthdate: moment(inputValues.dateOfBirth, "MM/DD/YYYY").format("YYYY-MM-DD"),
      firstName: contactInfo.legalFirstName,
      middleName: '',
      lastName: contactInfo.legalLastName,
      telephone: contactInfo.phoneNo.replace(/\((\d+)\)\s(\d+-\d+)/, '$1-$2'),
      addressStreet1: '',
      addressStreet2: '',
      addressCity: '',
      addressState: '',
      addressZip: '',
      ssn: inputValues.userSsn,
      emailAddress: profile.email,
      countryCode: 'US',
      crdIndividual: '',
      crdFirm: '',
      refId: `${accountId}_C`,
    };

    console.log("Personal Form Data", formData);
    let info = null;

    const result = await amlIdentityService.sendIndividual(formData);
    if (result) {
      const { "reference-id": resReferenceId } = result;

      for (let i = 0; i < 3; i++) {
        if (i > 0) {
          await delay(5000);
        }

        info = await amlIdentityService.getInfo(resReferenceId);
        console.log('c-info', info);
        if (info != null) {
          if (info.data === 'created') {
            // console.log('Transaction has been created.');
          } else if (info.data === 'approved') {
            // console.log('Transaction has been approved: you can proceed with the user.');
            break;
          } else if (info.data === 'declined') {
            // console.log('Transaction has been declined: verification unsuccessful or user otherwise declined.');
            break;
          } else if (info.data === 'needs_review') {
            // console.log('Transaction is awaiting manual review (there was a report hit)');
            break;
          } else if (info.data === 'errored') {
            // console.log('Transaction has errored.');
            break;
          }
        }
      }
    }
    
    dispatch({ type: dialogActionTypes.SHOW_DIALOG, value: { type: 'dialog_pending_verification', show: false, payment: null } });

    if (info == null || (info.data === 'created' || info.data === 'approved' || info.data === 'needs_review')) {
      return true;
    } else {
      dispatch({ type: dialogActionTypes.SHOW_DIALOG, value: { type: 'dialog_failed_verification', show: true, payment: {error: "Name is valid"} } });      
      return false;
    }
  }

  const handleSubmit = async () => {

    let retKYC = await doKYC();
    if (!retKYC) return;

    let retKYB = await doKYB();
    if (!retKYB) return;
   
    dispatch({ type: dialogActionTypes.SHOW_DIALOG, value: { type: 'dialog_pending_verification', show: true, payment: {title: contactInfo.entityName, content: 'Finishing up...'} } });
    updateFormData(inputValues);
  }

  useLayoutEffect(() => {
    async function update(e) {

      handleOptionClick("spinning-prohibition-certification");
    }

    if (!window) {
      return;
    }

    window.addEventListener('popstate', update)
    return () => window.removeEventListener('popstate', update);
  });

  const { inputValues, changeHandler, changeKeyHandler, errors, isValid, submitHandler, initValues, secrets, userSecrets, inputChanged, inputChangedHandler } =
    useForm(initialState, validations, onSubmit);

  return dataDoc ? (
    <div className="contactBg">
      <div className="mw-1440 mx-auto d-flex flex-lg-row flex-column bg-gray">
        <div className="trust-verification-info-container">
          <div className="trust-verification-logo-container mx-auto text-center text-lg-start">
            <Link to="/invest-trade/">
              <img src={dataDoc.data?.logo?.url} alt="Brand Logo" />
            </Link>
          </div>
          <div className="info-container">
            <div className="previous-navigation  text-center text-lg-start">
              <span onClick={() => {
                if (inputChanged) {
                  dispatch({ type: dialogActionTypes.SHOW_DIALOG, value: { type: 'dialog_leave_contact', show: true, showSub: false, subType: null, backValue: "spinning-prohibition-certification", isDeleteAccount: false } });
                } else {
                  handleOptionClick("spinning-prohibition-certification");
                }
              }}>
                &lt; Spinning Prohibition Certification
              </span>
            </div>
            <div className="question-no-container">
              {dataDoc.data?.action?.[0].text}
            </div>
            <div className="info-heading-container text-center text-lg-start">
              <p>{dataDoc.data?.title?.[0].text}</p>
            </div>
            <div
              className={clsx("info-desc-container", {
                oneline: seeMore && isMobile(),
              })}
            >
              <RichText render={dataDoc.data?.description} />
            </div>
            <div className="info-desc-container text-center text-lg-start">
              {isMobile() && (
                <>
                  <span
                    className="see-more-btn d-md-none"
                    style={{ marginTop: "12px" }}
                    onClick={() => setSeeMore(!seeMore)}
                  >
                    {seeMore ? "+ See more" : "- See less"} <br />
                  </span>
                </>
              )}
            </div>
          </div>
        </div>
        <div className="trust-verification-options-container d-flex flex-column">
          {loading && <div className="qualification-loading">
            <div className="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
          </div>}
          {dataDoc.data?.formheader?.length > 0 && (
            <>
              <div className="choose-container">
                <p className="m-0">{dataDoc.data?.formheader?.[0]?.text}</p>
              </div>
              <div className="choose-hint-container">
                <p>{dataDoc.data?.formheader?.[1]?.text}</p>
              </div>
            </>
          )}
          {(parentAccounts.length > 0 && parentAccounts?.[0]?.type === 'ria') && <>
          <div className="ml-20">
            <b>{newAccount?.contactInfo?.signersFirstName} {newAccount?.contactInfo?.signersLastName}</b>
          </div>
          <div>
            <TextInputField
              label="Date of birth"
              placeholder="MM/DD/YYYY"
              variant="filled"
              autoComplete="off"
              InputProps={{
                disableUnderline: true,
                style: {
                  borderColor: showError && errors.dateOfBirth && "#C75E5A",
                },
              }}
              onChange={changeHandler}
              name="dateOfBirth"
              value={inputValues.dateOfBirth}
              style={{ marginTop: 10, width: "100%" }}
              InputLabelProps={{
                style: {
                  color:
                    showError && errors.dateOfBirth
                      ? "#C75E5A"
                      : "rgba(0, 0, 0, 0.5)",
                },
              }}
            />
            {showError && errors.dateOfBirth && (
              <p className="error-message">{errors.dateOfBirth}</p>
            )}
          </div>
          <div>
            <TextInputField
              label="Social security number"
              placeholder="123-45-6789"
              variant="filled"
              autoComplete="off"
              InputProps={{
                disableUnderline: true,
                style: {
                  borderColor:
                    (showUserSsnExistError || (showError && errors.userSsn)) && "#C75E5A",
                },
              }}
              onChange={(e) => {setShowUserSsnExistError(false); changeHandler(e);}}
              onKeyDown={changeKeyHandler}
              name="userSsn"
              value={userSecrets}
              style={{ marginTop: 10, marginBottom: 20, width: "100%" }}
              InputLabelProps={{
                style: {
                  color:
                  (showUserSsnExistError || (showError && errors.userSsn)) 
                      ? "#C75E5A"
                      : "rgba(0, 0, 0, 0.5)",
                },
              }}
            />
            {showUserSsnExistError && (
              <p className="error-message">There is an established Trust account with the same SSN. Try again or contact Support for assistance.</p>
            )}
            {!showUserSsnExistError && showError && errors.userSsn && (
              <p className="error-message">{errors.userSsn}</p>
            )}
          </div>
          </>}
          {(parentAccounts.length === 0 || parentAccounts?.[0]?.type !== 'ria') && <>
          <div className="ml-20">
            <b>{newAccount?.contactInfo?.legalFirstName} {newAccount?.contactInfo?.legalLastName}</b>
          </div>
          <div>
            <TextInputField
              label="Date of birth"
              placeholder="MM/DD/YYYY"
              variant="filled"
              autoComplete="off"
              InputProps={{
                disableUnderline: true,
                style: {
                  borderColor: showError && errors.dateOfBirth && "#C75E5A",
                },
              }}
              onChange={changeHandler}
              name="dateOfBirth"
              value={inputValues.dateOfBirth}
              style={{ marginTop: 10, width: "100%" }}
              InputLabelProps={{
                style: {
                  color:
                    showError && errors.dateOfBirth
                      ? "#C75E5A"
                      : "rgba(0, 0, 0, 0.5)",
                },
              }}
            />
            {showError && errors.dateOfBirth && (
              <p className="error-message">{errors.dateOfBirth}</p>
            )}
          </div>
          <div>
            <TextInputField
              label="Social security number"
              placeholder="123-45-6789"
              variant="filled"
              autoComplete="off"
              InputProps={{
                disableUnderline: true,
                style: {
                  borderColor:
                    (showUserSsnExistError || (showError && errors.userSsn))  && "#C75E5A",
                },
              }}
              onChange={(e) => {setShowUserSsnExistError(false); changeHandler(e);}}
              onKeyDown={changeKeyHandler}
              name="userSsn"
              value={userSecrets}
              style={{ marginTop: 10, marginBottom: 20, width: "100%" }}
              InputLabelProps={{
                style: {
                  color:
                    (showUserSsnExistError || (showError && errors.userSsn))
                      ? "#C75E5A"
                      : "rgba(0, 0, 0, 0.5)",
                },
              }}
            />
            {showUserSsnExistError && (
              <p className="error-message">There is an established Trust account with the same SSN. Try again or contact Support for assistance.</p>
            )}
            {!showUserSsnExistError && showError && errors.userSsn && (
              <p className="error-message">{errors.userSsn}</p>
            )}
          </div>
          </>}
          <div className="ml-20">
            <b>{newAccount?.contactInfo?.entityName}</b>
          </div>
          <div>
            <TextInputField
              label="Date of formation"
              placeholder="MM/DD/YYYY"
              variant="filled"
              autoComplete="off"
              InputProps={{
                disableUnderline: true,
                style: {
                  borderColor: showError && errors.dateOfIncorporation && "#C75E5A",
                },
              }}
              onChange={changeHandler}
              name="dateOfIncorporation"
              value={inputValues.dateOfIncorporation}
              style={{ marginTop: 10, width: "100%" }}
              InputLabelProps={{
                style: {
                  color:
                    showError && errors.dateOfIncorporation
                      ? "#C75E5A"
                      : "rgba(0, 0, 0, 0.5)",
                },
              }}
            />
            {showError && errors.dateOfIncorporation && (
              <p className="error-message">{errors.dateOfIncorporation}</p>
            )}
          </div>
          {newAccount.contactInfo.taxIdentificationType === 'SSN' && <div>
            <TextInputField
              label="Social security number"
              placeholder="123-45-6789"
              variant="filled"
              autoComplete="off"
              InputProps={{
                disableUnderline: true,
                style: {
                  borderColor: (showEinExistError || (showError && errors.ssn)) && "#C75E5A",
                },
              }}
              onChange={(e) => {setShowEinExistError(false); changeHandler(e);}}
              onKeyDown={changeKeyHandler}
              name="ssn"
              value={secrets}
              style={{ marginTop: 10, width: "100%" }}
              InputLabelProps={{
                style: {
                  color:
                  (showEinExistError || (showError && errors.ssn))
                      ? "#C75E5A"
                      : "rgba(0, 0, 0, 0.5)",
                },
              }}
            />
            {showEinExistError && (
              <p className="error-message">There is an established Trust account with the same SSN. Try again or contact Support for assistance.</p>
            )}   
            {!showEinExistError && showError && errors.ssn && (
              <p className="error-message">{errors.ssn}</p>
            )}
          </div>}
          {newAccount.contactInfo.taxIdentificationType === 'EIN' && <div>
            <TextInputField
              label="EIN"
              placeholder="12-3456789"
              variant="filled"
              autoComplete="off"
              InputProps={{
                disableUnderline: true,
                style: {
                  borderColor: (showEinExistError || (showError && errors.ein)) && "#C75E5A",
                },
              }}
              onChange={(e) => {setShowEinExistError(false); changeHandler(e);}}
              onKeyDown={changeKeyHandler}
              name="ein"
              value={secrets}
              style={{ marginTop: 10, width: "100%" }}
              InputLabelProps={{
                style: {
                  color:
                    (showEinExistError || (showError && errors.ein))
                      ? "#C75E5A"
                      : "rgba(0, 0, 0, 0.5)",
                },
              }}
            />
            {showEinExistError && (
              <p className="error-message">There is an established Trust account with the same EIN. Try again or contact Support for assistance.</p>
            )} 
            {!showEinExistError && showError && errors.ein && (
              <p className="error-message">{errors.ein}</p>
            )}
          </div>}
          <div className="d-flex trust-terms-and-privacy">
            <div className="checkbox">
              <Checkbox
                checked={termsChecked}
                name="termsChecked"
                sx={{ "&.MuiCheckbox-root": { padding: 0 } }}
                onChange={(event) => setTermsChecked(event.target.checked)}
                disabled={!bottom}
              />
            </div>
            <div
              className="term-privacy-message"
              style={{
                borderColor: showError && !termsChecked && "#C75E5A",
              }}
              onScroll={handleScroll}
            >
              <RichText render={dataDoc.data?.terms} />
            </div>
          </div>
          {showError && !termsChecked && (
            <p className="error-message" style={{ marginLeft: 12 }}>
              Please accept Opalvest's Terms of Service and Privacy Policy.
            </p>
          )}
          <div className="continue-btn-container text-center">
            <Button
              disabled={!termsChecked}
              onClick={() => {
                if (!isValid) {
                  setShowError(true);
                } else {
                  inputChangedHandler(false);
                  // submitHandler();
                  handleSubmit();
                }
              }}
            >
              Submit
            </Button>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <Loader />
  );
};


function mapDispatchToProps(dispatch) {
  return {
    updateFormData: (value) => {
      dispatch({ type: actionTypes.UPDATE_ENTITY_STEP_THREE_FORM, value: { ...value, approvalTemplate: '' } })
    },
  }
}

export default connect(undefined, mapDispatchToProps)(TrustVerification);
