import React, { useState, useEffect, useLayoutEffect } from "react";
import { RichText } from "prismic-reactjs";
import moment from 'moment';
import clsx from "clsx";
import { connect, useSelector, useDispatch } from 'react-redux';
import { Button } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import { Link } from "gatsby";
import { client as prismicClient } from "../../../libs/prismic";
import { isMobile } from "../../../utils";
import { useForm } from "../../../hooks/useForm";
import {
  isRequired,
  isValidDate,
  isValidEIN,
  isValidSSN,
  isCRD,
} from "../../../utils/validationFunc";
import { actionTypes as dialogActionTypes } from "../../../store/Dialog/type";
import TextInputField from "../../TextInputField";
import Loader from "../../Loader";
import { actionTypes } from '../../../store/InvestorQualification/type';
import { moduleTypes } from "../../../store/type";
import AmlIdentityService from "../../../services/AmlIdentityService";
import AccountService from "../../../services/AccountService";

const amlIdentityService = new AmlIdentityService();
const accountService = new AccountService();

function delay(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

const RIAVerification = ({ handleOptionClick, updateFormData, individualStepTwoForm }) => {
  const [seeMore, setSeeMore] = useState(true);
  const [termsChecked, setTermsChecked] = useState(false);
  const [showError, setShowError] = useState(false);
  const [showSsnExistError, setShowSsnExistError] = useState(false);
  const [showEinExistError, setShowEinExistError] = useState(false);
  const [dataDoc, setDataDoc] = useState();
  const [bottom, setBottom] = useState(false);
  const [isBusinessLocked, setIsBusinessLocked] = useState(false);

  const initialState = {
    dateOfBirth: "",
    userSsn: "",
    dateOfIncorporation: "",
    ein: "",
    crdIndividual: "",
    crdFirm: "",
    termsChecked: false,
  };

  const newAccount = useSelector(
    (state) => state?.accounts?.newAccount
  );

  const contactInfo = useSelector(
    (state) => state?.accounts?.newAccount?.contactInfo
  );

  const parentAccounts = useSelector(
    (state) => state?.accounts?.parentAccounts
  );

  const profile = useSelector(
    (state) => state?.app?.profile
  );

  const loading = useSelector(
    (state) => state?.investor_qualification?.isFormUpdateLoading
  );

  const dispatch = useDispatch();

  useEffect(() => {
    async function fetchData() {
      const response = await prismicClient().getSingle("verification_ria_2");
      setDataDoc(response);
    }
    fetchData();
  }, []);

  useEffect(() => {
    console.log("newAccount", newAccount);
    if (newAccount?.contactInfo?.hasOwnProperty('verifiedBusiness') ) {
      console.log("verifiedBusiness", newAccount.contactInfo.verifiedBusiness);

      setIsBusinessLocked(true);
      initValues({
        dateOfBirth: "",
        userSsn: "",
        crdIndividual: "",
        dateOfIncorporation: newAccount.contactInfo.verifiedBusiness.dateOfIncorporation ?? '01/01/1970',
        ein: newAccount.contactInfo.verifiedBusiness.secrets?.ein ?? '12-3456789',
        crdFirm: newAccount.contactInfo.verifiedBusiness.crdFirm ?? '0000000',
        termsChecked: false,
      });
    }
  }, [newAccount]);

  const checkSsnValid = async (accountId, ssn, firstName, lastName) => {
    if (!ssn || !isValidSSN(ssn))
      return false;
    
    const result = await accountService.checkSsnUsable(accountId, ssn, firstName, lastName) 
    if (result && result.result) {
      return true;
    }

    return false;
  }

  const checkEinValid = async (accountId, ein, companyName) => {
    if (!ein || !isValidEIN(ein))
      return false;
    
    const result = await accountService.checkEinUsable(accountId, ein, companyName) 
    if (result && result.result) {
      return true;
    }

    return false;
  }
  
  const validations = [
    ({ dateOfBirth }) =>
      isRequired(dateOfBirth) || {
        dateOfBirth: "This field is required",
      },
    ({ dateOfBirth }) =>
      isValidDate(dateOfBirth) || {
        dateOfBirth: "Enter date in MM/DD/YYYY format ONLY.",
      },
    ({ userSsn }) =>
      isRequired(userSsn) || {
        userSsn: "This field is required",
      },
    ({ userSsn }) =>
      isValidSSN(userSsn) || {
        userSsn: "SSN in 123-45-6789 format ONLY.",
      },
    ({ dateOfIncorporation }) =>
      isRequired(dateOfIncorporation) || {
        dateOfIncorporation: "This field is required",
      },
    ({ dateOfIncorporation }) =>
      isValidDate(dateOfIncorporation) || {
        dateOfIncorporation: "Enter date in MM/DD/YYYY format ONLY.",
      },
    ({ ein }) =>
      isRequired(ein) || {
        ein: "This field is required",
      },
    ({ ein }) =>
      isValidEIN(ein) || {
        ein: "Enter EIN in 12-3456789 format ONLY.",
      },
    ({ crdIndividual }) =>
      isCRD(crdIndividual) || {
        crdIndividual: "Enter CRD number format ONLY",
      },
    ({ crdIndividual }) =>
      isRequired(crdIndividual) || {
        crdIndividual: "This field is required",
      },
    ({ crdFirm }) =>
      isCRD(crdFirm) || {
        crdFirm: "Enter CRD number format ONLY",
      },
    ({ crdFirm }) =>
      isRequired(crdFirm) || {
        crdFirm: "This field is required",
      },
  ];

  const onSubmit = (value) => {

  };

  const doKYB = async () => {

    if (isBusinessLocked)
      return true;
    
    dispatch({ type: dialogActionTypes.SHOW_DIALOG, value: { type: 'dialog_pending_verification', show: true, payment: { title: contactInfo.riaCompany } } });

    const accountId = newAccount ? newAccount._id : (parentAccounts.length > 0 ? parentAccounts[parentAccounts.length - 1]._id : "");

    if (!await checkEinValid(accountId, inputValues.ein)) {
      dispatch({ type: dialogActionTypes.SHOW_DIALOG, value: { type: 'dialog_pending_verification', show: false, payment: null } });
      setShowEinExistError(true);
      return;
    }

    const formData = {
      businessName: contactInfo.riaCompany,
      businessTaxIdentificationNumber: inputValues.ein,
      businessPhysicalAddress: {
        street1: contactInfo.mailChecked == 1 ? contactInfo.mailAddressLineOne : contactInfo.addressLineOne,
        street2: contactInfo.mailChecked == 1 ? contactInfo.mailAddressLineTwo : contactInfo.addressLineTwo,
        city: contactInfo.mailChecked == 1 ? contactInfo.mailCity : contactInfo.city,
        subdivision: contactInfo.mailChecked == 1 ? contactInfo.mailState : contactInfo.state,
        postalCode: contactInfo.mailChecked == 1 ? contactInfo.mailZipcode : contactInfo.zipcode,
        countryCode: 'US',
      },
      businessRegisteredAddress: {
        street1: contactInfo.addressLineOne,
        street2: contactInfo.addressLineTwo,
        city: contactInfo.city,
        subdivision: contactInfo.state,
        postalCode: contactInfo.zipcode,
        countryCode: 'US',
      },
      crdIndividual: '',
      crdFirm: inputValues.crdFirm,
      refId: accountId,
    };

    // console.log("formData", formData);
    let info = null;
    const result = await amlIdentityService.sendBusiness(formData);
    if (result) {
      const { "reference-id": resReferenceId } = result;

      for (let i = 0; i < 3; i++) {
        if (i > 0) {
          await delay(5000);
        }

        info = await amlIdentityService.getInfo(resReferenceId);
        console.log('info', info);
        if (info != null) {
          if (info.data === 'created') {
            // console.log('Transaction has been created.');
          } else if (info.data === 'approved') {
            // console.log('Transaction has been approved: you can proceed with the user.');
            break;
          } else if (info.data === 'declined') {
            // console.log('Transaction has been declined: verification unsuccessful or user otherwise declined.');
            break;
          } else if (info.data === 'needs_review') {
            // console.log('Transaction is awaiting manual review (there was a report hit)');
            break;
          } else if (info.data === 'errored') {
            // console.log('Transaction has errored.');
            break;
          }
        }
      }
    }
    
    dispatch({ type: dialogActionTypes.SHOW_DIALOG, value: { type: 'dialog_pending_verification', show: false, payment: null } });

    if (info == null || (info.data === 'created' || info.data === 'approved' || info.data === 'needs_review')) {
      return true;
    } else {
      dispatch({ type: dialogActionTypes.SHOW_DIALOG, value: { type: 'dialog_failed_verification', show: true, payment: {error: "Name is valid"} } });      
      return false;
    }
  }

  const doKYC = async () => {
    dispatch({ type: dialogActionTypes.SHOW_DIALOG, value: { type: 'dialog_pending_verification', show: true, payment: {title: `${contactInfo.legalFirstName} ${contactInfo.legalLastName}`} } });

    const accountId = newAccount ? newAccount._id : (parentAccounts.length > 0 ? parentAccounts[parentAccounts.length - 1]._id : "");

    if (!await checkSsnValid(accountId, inputValues.userSsn)) {
      dispatch({ type: dialogActionTypes.SHOW_DIALOG, value: { type: 'dialog_pending_verification', show: false, payment: null } });
      setShowSsnExistError(true);
      return false;
    }

    const formData = {
      birthdate: moment(inputValues.dateOfBirth, "MM/DD/YYYY").format("YYYY-MM-DD"),
      firstName: contactInfo.legalFirstName,
      middleName: '',
      lastName: contactInfo.legalLastName,
      telephone: contactInfo.phoneNo.replace(/\((\d+)\)\s(\d+-\d+)/, '$1-$2'),
      addressStreet1: '',
      addressStreet2: '',
      addressCity: '',
      addressState: '',
      addressZip: '',
      ssn: inputValues.userSsn,
      emailAddress: profile.email,
      countryCode: 'US',
      crdIndividual: inputValues.crdIndividual,
      crdFirm: '',
      refId: `${accountId}_C`,
    };

    console.log("Personal Form Data", formData);
    let info = null;
    const result = await amlIdentityService.sendIndividual(formData);
    if (result) {
      const { "reference-id": resReferenceId } = result;

      for (let i = 0; i < 3; i++) {
        if (i > 0) {
          await delay(5000);
        }

        info = await amlIdentityService.getInfo(resReferenceId);
        console.log('c-info', info);
        if (info != null) {
          if (info.data === 'created') {
            // console.log('Transaction has been created.');
          } else if (info.data === 'approved') {
            // console.log('Transaction has been approved: you can proceed with the user.');
            break;
          } else if (info.data === 'declined') {
            // console.log('Transaction has been declined: verification unsuccessful or user otherwise declined.');
            break;
          } else if (info.data === 'needs_review') {
            // console.log('Transaction is awaiting manual review (there was a report hit)');
            break;
          } else if (info.data === 'errored') {
            // console.log('Transaction has errored.');
            break;
          }
        }
      }
    }
    
    dispatch({ type: dialogActionTypes.SHOW_DIALOG, value: { type: 'dialog_pending_verification', show: false, payment: null } });

    if (info == null || (info.data === 'created' || info.data === 'approved' || info.data === 'needs_review')) {
      return true;
    } else {
      dispatch({ type: dialogActionTypes.SHOW_DIALOG, value: { type: 'dialog_failed_verification', show: true, payment: {error: "Name is valid"} } });      
      return false;
    }
  }

  const handleSubmit = async () => {

    let retKYC = await doKYC();
    if (!retKYC) return;

    let retKYB = await doKYB();
    if (!retKYB) return;

    dispatch({ type: dialogActionTypes.SHOW_DIALOG, value: { type: 'dialog_pending_verification', show: true, payment: {title: contactInfo.riaCompany, content: 'Finishing up...'} } });    

    updateFormData(inputValues);
  }

  const handleScroll = (e) => {
    const _bottom = Math.abs(e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight) < 10;
    if (_bottom) {
      setTermsChecked(true)
      setBottom(true);
    }
  };

  useLayoutEffect(() => {
    async function update(e) {

      handleOptionClick("investor-type-ria")
    }

    if (!window) {
      return;
    }

    window.addEventListener('popstate', update)
    return () => window.removeEventListener('popstate', update);
  });

  const { inputValues, secrets, userSecrets, changeHandler, changeKeyHandler, errors, isValid, submitHandler, initValues, inputChanged, inputChangedHandler } =
    useForm(initialState, validations, onSubmit);

  return dataDoc ? (
    <div className="contactBg">
      <div className="mw-1440 mx-auto d-flex flex-lg-row flex-column bg-gray">
        <div className="ria-verification-info-container">
          <div className="ria-verification-logo-container mx-auto  text-center text-lg-start">
            <Link to={parentAccounts?.[0]?.type === 'ria' && parentAccounts?.[0]?.verification === 'approved' ? "/invest-trade/" : "/"}>
              <img src={dataDoc.data?.logo?.url} alt="Brand Logo" />
            </Link>
          </div>
          <div className="info-container">
            <div className="previous-navigation  text-center text-lg-start">
              <span
                onClick={() => {
                  if (inputChanged) {
                    dispatch({ type: dialogActionTypes.SHOW_DIALOG, value: { type: 'dialog_leave_contact', show: true, showSub: false, subType: null } });
                  } else {
                    handleOptionClick("investor-type-ria");
                  }
                }}
              >
                &lt; Contact Information
              </span>
            </div>
            <div className="question-no-container">
              <p>{dataDoc.data?.action?.[0].text}</p>
            </div>
            <div className="info-heading-container text-center text-lg-start">
              <p>{dataDoc.data?.title?.[0].text}</p>
            </div>
            <div
              className={clsx("info-desc-container", {
                oneline: seeMore && isMobile(),
              })}
            >
              <RichText render={dataDoc.data?.description} />
            </div>
            <div className="info-desc-container text-center text-lg-start">
              {isMobile() && (
                <>
                  <span
                    className="see-more-btn d-md-none"
                    style={{ marginTop: "12px" }}
                    onClick={() => setSeeMore(!seeMore)}
                  >
                    {seeMore ? "+ See more" : "- See less"} <br />
                  </span>
                </>
              )}
            </div>
          </div>
        </div>
        <div className="ria-verification-options-container d-flex flex-column">
          {loading && <div className="qualification-loading">
            <div className="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
          </div>}
          {dataDoc.data?.formheader?.length > 0 && (
            <>
              <div className="choose-container">
                <p className="m-0">{dataDoc.data?.formheader?.[0]?.text}</p>
              </div>
              <div className="choose-hint-container">
                <p>{dataDoc.data?.formheader?.[1]?.text}</p>
              </div>
            </>
          )}
          <div className="ml-20">
            <b>{newAccount?.contactInfo?.legalFirstName} {newAccount?.contactInfo?.legalLastName}</b>
          </div>
          <div>
            <TextInputField
              label="Date of birth"
              placeholder="MM/DD/YYYY"
              variant="filled"
              autoComplete="off"
              InputProps={{
                disableUnderline: true,
                style: {
                  borderColor: showError && errors.dateOfBirth && "#C75E5A",
                },
              }}
              onChange={changeHandler}
              name="dateOfBirth"
              value={inputValues.dateOfBirth}
              style={{ marginTop: 10, width: "100%" }}
              InputLabelProps={{
                style: {
                  color:
                    showError && errors.dateOfBirth
                      ? "#C75E5A"
                      : "rgba(0, 0, 0, 0.5)",
                },
              }}
            />
            {showError && errors.dateOfBirth && (
              <p className="error-message">{errors.dateOfBirth}</p>
            )}
          </div>
          <div>
            <TextInputField
              label="Social security number"
              placeholder="123-45-6789"
              variant="filled"
              autoComplete="off"
              InputProps={{
                disableUnderline: true,
                style: {
                  borderColor:
                    (showSsnExistError || (showError && errors.userSsn)) && "#C75E5A",
                },
              }}
              onChange={(e) => {setShowSsnExistError(false); changeHandler(e);}}
              onKeyDown={changeKeyHandler}
              name="userSsn"
              value={userSecrets}
              style={{ marginTop: 10, width: "100%" }}
              InputLabelProps={{
                style: {
                  color:
                    (showSsnExistError || (showError && errors.userSsn))
                      ? "#C75E5A"
                      : "rgba(0, 0, 0, 0.5)",
                },
              }}
            />
            {showSsnExistError && (
              <p className="error-message">There is an established RIA account with the same SSN. Try again or contact Support for assistance.</p>
            )}
            {!showSsnExistError && showError && errors.userSsn && (
              <p className="error-message">{errors.userSsn}</p>
            )}
          </div>
          <div>
            <TextInputField
              label="Individual CRD #"
              variant="filled"
              autoComplete="off"
              InputProps={{
                disableUnderline: true,
                style: { borderColor: showError && errors.crdIndividual && "#C75E5A" },
              }}
              onChange={changeHandler}
              name="crdIndividual"
              value={inputValues.crdIndividual}
              style={{ marginTop: 10, marginBottom: 20, width: "100%" }}
              InputLabelProps={{
                style: {
                  color:
                    showError && errors.crdIndividual ? "#C75E5A" : "rgba(0, 0, 0, 0.5)",
                },
              }}
            />
            {showError && errors.crdIndividual && (
              <p className="error-message">{errors.crdIndividual}</p>
            )}
          </div>
          <div className="ml-20">
            <b>{newAccount?.contactInfo?.riaCompany}</b>
            <div>Registered Investment Advisor</div>
          </div>
          <div>
            <TextInputField
              label="Date of formation"
              placeholder="MM/DD/YYYY"
              disabled={isBusinessLocked}
              variant="filled"
              autoComplete="off"
              InputProps={{
                disableUnderline: true,
                style: {
                  borderColor:
                    showError && errors.dateOfIncorporation && "#C75E5A",
                },
              }}
              onChange={changeHandler}
              name="dateOfIncorporation"
              value={inputValues.dateOfIncorporation}
              style={{ marginTop: 10, width: "100%" }}
              InputLabelProps={{
                style: {
                  color:
                    showError && errors.dateOfIncorporation
                      ? "#C75E5A"
                      : "rgba(0, 0, 0, 0.5)",
                },
              }}
            />
            {showError && errors.dateOfIncorporation && (
              <p className="error-message">{errors.dateOfIncorporation}</p>
            )}
          </div>
          <div>
            <TextInputField
              label="EIN"
              placeholder="12-3456789"
              disabled={isBusinessLocked}
              variant="filled"
              autoComplete="off"
              InputProps={{
                disableUnderline: true,
                style: { borderColor: (showEinExistError || (showError && errors.ein)) && "#C75E5A",},
              }}
              onChange={(e) => {setShowEinExistError(false); changeHandler(e);}}
              onKeyDown={changeKeyHandler}
              name="ein"
              value={secrets}
              style={{ marginTop: 10, width: "100%" }}
              InputLabelProps={{
                style: {
                  color:
                    showEinExistError || (showError && errors.ein) ? "#C75E5A" : "rgba(0, 0, 0, 0.5)",
                },
              }}
            />
            {showEinExistError && (
              <p className="error-message">There is an established RIA account with the same EIN. Try again or contact Support for assistance.</p>
            )}
            {!showEinExistError && showError && errors.ein && (
              <p className="error-message">{errors.ein}</p>
            )}
          </div>
          <div>
            <TextInputField
              label="Firm CRD #"
              variant="filled"
              disabled={isBusinessLocked}
              autoComplete="off"
              InputProps={{
                disableUnderline: true,
                style: { borderColor: showError && errors.crdFirm && "#C75E5A" },
              }}
              onChange={changeHandler}
              name="crdFirm"
              value={inputValues.crdFirm}
              style={{ marginTop: 10, width: "100%" }}
              InputLabelProps={{
                style: {
                  color:
                    showError && errors.crdFirm ? "#C75E5A" : "rgba(0, 0, 0, 0.5)",
                },
              }}
            />
            {showError && errors.crdFirm && (
              <p className="error-message">{errors.crdFirm}</p>
            )}
          </div>
          <div className="d-flex ria-terms-and-privacy">
            <div className="checkbox">
              <Checkbox
                checked={termsChecked}
                name="termsChecked"
                sx={{ "&.MuiCheckbox-root": { padding: 0 } }}
                onChange={(event) => setTermsChecked(event.target.checked)}
                disabled={!bottom}
              />
            </div>
            <div
              className="term-privacy-message"
              style={{
                borderColor: showError && !termsChecked && "#C75E5A",
              }}
              onScroll={handleScroll}
            >
              <RichText render={dataDoc.data?.terms} />
            </div>
          </div>
          {showError && !termsChecked && (
            <p className="error-message" style={{ marginLeft: 12 }}>
              Please accept Opalvest's Terms of Service and Privacy Policy.
            </p>
          )}
          <div className="continue-btn-container text-center">
            <Button
              disabled={!termsChecked}
              onClick={() => {
                if (!isValid) {
                  setShowError(true);
                } else {
                  inputChangedHandler(false);
                  handleSubmit();
                  //submitHandler();
                }
              }}
            >
              Submit
            </Button>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <Loader />
  );
};


const mapStateToProps = (state) => {
  return {
    entityStepThreeForm: state[moduleTypes.INVESTOR_QUALIFICATION].entityStepThreeForm,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    updateFormData: (value) => {
      dispatch({ type: actionTypes.UPDATE_ENTITY_STEP_THREE_FORM, value })
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RIAVerification);

