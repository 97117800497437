import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { client as prismicClient } from "../../../../libs/prismic";
import { isMobile } from "../../../../utils";
import { RichText } from "prismic-reactjs";
import clsx from "clsx";
import { Link } from "gatsby";
import Loader from "../../../Loader";
import { actionTypes as dialogActionTypes } from "../../../../store/Dialog/type";
import BusinessQualificationItemTwoContent from "../BusinessQualificationItemTwoContent";

const BusinessQualificationItemTwo = ({ handleOptionClick }) => {
  const dispatch = useDispatch();
  const [seeMore, setSeeMore] = useState(true);
  const [dataDoc, setDataDoc] = useState();
  const [isEnableSave, setEnableSave] = useState(false);

  const parentAccounts = useSelector(
    (state) => state?.accounts?.parentAccounts
  );

  useEffect(() => {
    async function fetchData(mode) {
      const response = await prismicClient().getSingle(mode);
      setDataDoc(response);
    }

    if (parentAccounts.length > 0 && parentAccounts[0].type === 'ria') {
      fetchData('qualification_2_ria_business_2');
    } else {
      fetchData('qualification_2_business_2');
    }
  }, [parentAccounts]); 

  return dataDoc ? (
    <div className="qualification-item-page contactBg">
      <div className="mw-1440 mx-auto d-flex flex-lg-row flex-column">
        <div className="qualification-info-container">
          <div className="qualification-logo-container mx-auto">
            <Link to="/">
              <img src={dataDoc.data?.logo?.url} alt="Brand Logo" />
            </Link>
          </div>
          <div className="previous-navigation text-center text-lg-start">
            <span onClick={() => {
              if (isEnableSave) {
                dispatch({ type: dialogActionTypes.SHOW_DIALOG, value: { type: 'dialog_leave_contact', show: true, showSub: false, subType: null, backValue: "qualification-business-one", isDeleteAccount: false } });
              } else {
                handleOptionClick("qualification-business-one");
              }
            }}>
              &lt; Investor Qualification 1 of 2
            </span>
          </div>
          <div className="question-container">
            <div className="question-no-container">
              <p>{dataDoc.data?.action?.[0].text}</p>
            </div>
            <div className="question-container">
              <p>{dataDoc.data?.title?.[0].text}</p>
            </div>
            <div
              className={clsx("question-desc-container", {
                oneline: seeMore && isMobile(),
              })}
            >
              <RichText render={dataDoc.data?.description} />
            </div>
            <div className="question-desc-container">
              {isMobile() && (
                <>
                  <span
                    className="see-more-btn d-md-none"
                    style={{ marginTop: "12px" }}
                    onClick={() => setSeeMore(!seeMore)}
                  >
                    {seeMore ? "+ See more" : "- See less"} <br />
                  </span>
                </>
              )}
            </div>
          </div>
        </div>
        
        <BusinessQualificationItemTwoContent
            handleOptionClick={ handleOptionClick } />

      </div>
    </div>
  ) : (
    <Loader />
  );
};


export default BusinessQualificationItemTwo;