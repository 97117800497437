import React, { useState, useEffect } from "react";
import { client as prismicClient } from "../../../../libs/prismic";
import { Button } from "react-bootstrap";
import { isMobile } from "../../../../utils";
import { RichText } from "prismic-reactjs";
import clsx from "clsx";
import QuestionOption from "../QuestionOption";
import { Link, navigate } from "gatsby";
import Loader from "../../../Loader";

const QualificationItemOne = ({ handleOptionClick }) => {
  const [seeMore, setSeeMore] = useState(true);
  const [selectedOption, setSelectedOption] = useState([]);
  const [dataDoc, setDataDoc] = useState();

  useEffect(() => {
    async function fetchData () {
      const response = await prismicClient().getSingle("qualification_1");
      setDataDoc(response);
    }
    fetchData();
  }, []);

  const optionClick = (e) => {
    if (e === "None of the above") {
      setSelectedOption([e]);
    } else {
      if (selectedOption.includes(e)) {
        setSelectedOption(selectedOption.filter((el) => el !== e));
      } else {
        setSelectedOption([
          ...selectedOption.filter((el) => el !== "None of the above"),
          e,
        ])
      }
    }
  };

  const handleClick = () => {
    const qualified = selectedOption.every( val => !/None/i.test(val) )
    handleOptionClick("qualification-item-two", qualified);
    if(!qualified) {
      navigate('/')
      return;
    }
  }

  return dataDoc ? (
    <div className="qualification-item-page contactBg">
      <div className="mw-1440 mx-auto d-flex flex-lg-row flex-column">
        <div className="qualification-info-container">
          <div className="qualification-logo-container mx-auto">
            <Link to="/">
              <img src={dataDoc.data?.logo?.url} alt="Brand Logo" />
            </Link>
          </div>
          <div className="question-container">
            <div className="question-no-container">
              <p>{dataDoc.data?.action?.[0].text}</p>
            </div>
            <div className="question-container">
              <p>{dataDoc.data?.title?.[0].text}</p>
            </div>
            <div
              className={clsx("question-desc-container", {
                oneline: seeMore && isMobile(),
              })}
            >
              <RichText render={dataDoc.data?.description} />
            </div>
            <div className="question-desc-container">
              {isMobile() && (
                <>
                  <span
                    className="see-more-btn d-md-none"
                    style={{ marginTop: "12px" }}
                    onClick={() => setSeeMore(!seeMore)}
                  >
                    {seeMore ? "+ See more" : "- See less"} <br />
                  </span>
                </>
              )}
            </div>
          </div>
        </div>
        <div className="qualification-options-container">
          {dataDoc.data?.formheader?.length > 0 && (
            <>
              <div className="choose-container">
                <p className="m-0">{dataDoc.data?.formheader?.[0]?.text}</p>
              </div>
              <div className="choose-hint-container">
                <p>{dataDoc.data?.formheader?.[1]?.text}</p>
              </div>
            </>
          )}
          {dataDoc.data?.questions?.map((item) => (
            <QuestionOption
              key={item?.question?.[0].text}
              option={item?.question?.[0].text}
              handleClick={optionClick}
              selected={selectedOption.some(
                (el) => el === item?.question?.[0].text
              )}
            />
          ))}
          <div className="qualification-btn-container text-center">
            <Button
              onClick={() => handleClick()}
              disabled={!selectedOption.length}
            >
              Continue
            </Button>
          </div>
          <br />
          <br />
        </div>
      </div>
    </div>
  ) : (
    <Loader />
  );
};

export default QualificationItemOne;
